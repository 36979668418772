import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaTachometerAlt, FaFileInvoice, FaBox, FaUsers, FaChartLine, FaBuilding, FaLock, FaCogs } from 'react-icons/fa'; // Import the icons
import CompanyModal from './CompanyModal';
import { checkJwtExpiration } from '../auth-helpers';

const NavigationBar = ({ handleLogout }) => {
  const [showCompanyModal, setShowCompanyModal] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false); // State for sidebar toggle
  const navigate = useNavigate();

  useEffect(() => {
    const validateToken = async () => {
      await checkJwtExpiration(navigate);
    };

    validateToken();
    const interval = setInterval(validateToken, 60000);

    return () => clearInterval(interval);
  }, [navigate]);

  const handleNavigation = (path) => {
    navigate(path);
    setMenuOpen(false); // Close sidebar after click
  };

  const handleCloseModal = () => {
    setShowCompanyModal(false);
  };

  return (
    <>
      {/* Top Navigation Bar */}
      <nav className="bg-transparent fixed top-0 left-0 right-0 z-10 md:z-20 md:relative">
        <div className="flex justify-between items-center px-4 py-4">
          <div className="flex items-center">
            {/* Hamburger Menu for Sidebar */}
            <button
              onClick={() => setMenuOpen(!menuOpen)}
              className="text-gray-800 focus:outline-none md:hidden z-20 relative"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d={menuOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16m-7 6h7"}
                />
              </svg>
            </button>
            <h1 className="text-3xl font-bold text-purple-600">
              Transacly.
            </h1>
          </div>

          {/* Support and Logout Buttons */}
          <div className="flex space-x-4 pr-4">
            <button
              className="text-gray-800 border border-gray-300 p-2 rounded transition duration-200 hover:bg-purple-100 hover:bg-opacity-50"
              onClick={() => handleNavigation('/support')}
            >
              Support
            </button>
            <button
              onClick={handleLogout}
              className="bg-red-500 text-white border border-red-500 p-2 rounded transition duration-200 hover:bg-red-600"
            >
              Logout
            </button>
          </div>
        </div>
      </nav>

      {/* Sidebar Navigation */}
      <div
        className={`fixed inset-y-0 left-0 w-64 bg-white shadow-md z-20 transform ${
          menuOpen ? 'translate-x-0' : '-translate-x-full'
        } transition-transform duration-300 md:translate-x-0`}
      >
        <div className="px-4 py-6">
          <h1 className="text-xl font-bold mb-6">Transacly.co</h1> {/* Transacly.co at the top of the sidebar */}
          
          {/* Overview Section */}
          <div className="mb-6">
            <h2 className="font-md mb-2 text-gray-800 font-semibold">Overview</h2>
            <button
              className="flex items-center w-full text-left px-4 py-2 font-medium rounded-md text-gray-700 transition-transform duration-200 ease-in-out hover:scale-104 hover:bg-gray-100"
              onClick={() => handleNavigation('/dashboard')}
            >
              <FaTachometerAlt className="mr-2" /> Dashboard
            </button>
            <button
              className="flex items-center w-full text-left px-4 py-2 font-medium rounded-md text-gray-700 transition-transform duration-200 ease-in-out hover:scale-104 hover:bg-gray-100"
              onClick={() => handleNavigation('/invoiceList')}
            >
              <FaFileInvoice className="mr-2" /> Invoices
            </button>
          </div>

          {/* Manage Section */}
          <div className="mb-6">
            <h2 className="font-md mb-2 text-gray-800 font-semibold">Manage</h2>
            <button
              className="flex items-center w-full text-left px-4 py-2 font-medium rounded-md text-gray-700 transition-transform duration-200 ease-in-out hover:scale-104 hover:bg-gray-100"
              onClick={() => handleNavigation('/productManagement')}
            >
              <FaBox className="mr-2" /> Products
            </button>
            <button
              className="flex items-center w-full text-left px-4 py-2 font-medium rounded-md text-gray-700 transition-transform duration-200 ease-in-out hover:scale-104 hover:bg-gray-100"
              onClick={() => handleNavigation('/subscriptions')}
            >
              <FaCogs className="mr-2" /> Subscriptions
            </button>
            <button
              className="flex items-center w-full text-left px-4 py-2 font-medium rounded-md text-gray-700 transition-transform duration-200 ease-in-out hover:scale-104 hover:bg-gray-100"
              onClick={() => handleNavigation('/customers')}
            >
              <FaUsers className="mr-2" /> Customers
            </button>
            <button
              className="flex items-center w-full text-left px-4 py-2 font-medium rounded-md text-gray-700 transition-transform duration-200 ease-in-out hover:scale-104 hover:bg-gray-100"
              onClick={() => handleNavigation('/analytics')}
            >
              <FaChartLine className="mr-2" /> Analytics
            </button>
          </div>

          {/* Settings Section */}
          <div>
            <h2 className="font-md mb-2 text-gray-800 font-semibold">Settings</h2>
            <button
              className="flex items-center w-full text-left px-4 py-2 font-medium rounded-md text-gray-700 transition-transform duration-200 ease-in-out hover:scale-104 hover:bg-gray-100"
              onClick={() => setShowCompanyModal(true)}
            >
              <FaBuilding className="mr-2" /> Company Info
            </button>
            <button
              className="flex items-center w-full text-left px-4 py-2 font-medium rounded-md text-gray-700 transition-transform duration-200 ease-in-out hover:scale-104 hover:bg-gray-100"
              onClick={() => handleNavigation('/security')}
            >
              <FaLock className="mr-2" /> Security
            </button>
          </div>
        </div>
      </div>

      {showCompanyModal && <CompanyModal onClose={handleCloseModal} />}
    </>
  );
};

export default NavigationBar;