import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

function Signup() {
  const [step, setStep] = useState(1);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false); // Loading state
  const [validated, setValidated] = useState(false); // Validation success state
  const [loginInfo, setLoginInfo] = useState({ name: '', email: '', password: '' });
  const [passwordStrength, setPasswordStrength] = useState('');
  const [stripeKey, setStripeKey] = useState(''); // Single field for restricted key
  const [missingPermissions, setMissingPermissions] = useState([]); // Track missing permissions
  const [companyInfo, setCompanyInfo] = useState({
    company_name: '',
    address_line1: '',
    address_line2: '',
    city: '',
    state: '',
    postal_code: '',
    country: '',
    vat_number: ''
  });
  const [agreeToPolicies, setAgreeToPolicies] = useState(false);
  const [signupEnabled, setSignupEnabled] = useState(true);
  const navigate = useNavigate();
  const [token, setToken] = useState('');
  const [showKeyTooltip, setShowKeyTooltip] = useState(false); // Tooltip for restricted key

  useEffect(() => {
    const fetchSignupStatus = async () => {
      try {
        const response = await axios.get('https://api.transacly.co/signup-status');
        setSignupEnabled(response.data.signupEnabled);
      } catch (error) {
        console.error('Error fetching signup status:', error);
      }
    };

    fetchSignupStatus();
  }, []);

  const sanitizeInput = (input) => {
    const element = document.createElement('div');
    element.innerText = input;
    return element.innerHTML;
  };

  const handlePasswordChange = (e) => {
    const password = e.target.value;
    setLoginInfo({ ...loginInfo, password });

    const strength = evaluatePasswordStrength(password);
    setPasswordStrength(strength);
  };

  const evaluatePasswordStrength = (password) => {
    let strength = 0;
    if (password.length >= 8) strength++;
    if (/[A-Z]/.test(password)) strength++;
    if (/[a-z]/.test(password)) strength++;
    if (/[0-9]/.test(password)) strength++;
    if (/[^A-Za-z0-9]/.test(password)) strength++;
    return strength;
  };

  const handleNextStep = async () => {
    if (step === 1) {
      // Validate loginInfo
      if (!loginInfo.name || !loginInfo.email || !loginInfo.password) {
        setError('Please fill in all fields marked with *.');
        return;
      }
      if (passwordStrength < 4) {
        setError('Password is too weak. Please use a stronger password.');
        return;
      }
      // Sanitize inputs
      loginInfo.name = sanitizeInput(loginInfo.name);
      loginInfo.email = sanitizeInput(loginInfo.email);
    } else if (step === 2) {
      // Validate Stripe restricted key
      if (!stripeKey) {
        setError('Please enter your Stripe restricted key.');
        return;
      }
      const isTestKey = stripeKey.startsWith('rk_test_');
      if (isTestKey) {
        setError('Test keys are not allowed. Please use a live Stripe restricted key.');
        return;
      }

      // Set loading to true before starting verification
      setLoading(true);
      setError('');

      try {
        const response = await axios.post('https://api.transacly.co/validate-stripe-keys', { privateKey: stripeKey });
        if (response.status === 200) {
          setValidated(true); // Keys are validated
          setTimeout(() => {
            setLoading(false);
            setStep(step + 1); // Proceed to the next step
          }, 1500); // Delay to show success checkmark before moving to the next step
        }
      } catch (error) {
        setError('Invalid Stripe restricted key. Please check your key and try again.');
        setLoading(false); // Stop loading if there's an error
        return;
      }
    } else if (step === 3) {
      // Validate companyInfo
      if (!companyInfo.company_name || !companyInfo.address_line1 || !companyInfo.city ||
          !companyInfo.state || !companyInfo.postal_code || !companyInfo.country) {
        setError('Please fill in all required fields.');
        return;
      }
      // Sanitize inputs
      for (let key in companyInfo) {
        companyInfo[key] = sanitizeInput(companyInfo[key]);
      }
    }
    setStep(step + 1);
  };

  const handlePrevStep = () => {
    setStep(step - 1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Validate final step fields before submission
    if (!companyInfo.company_name || !companyInfo.address_line1 || !companyInfo.city ||
        !companyInfo.state || !companyInfo.postal_code || !companyInfo.country) {
      setError('Please fill in all required fields.');
      return;
    }
  
    if (!agreeToPolicies) {
      setError('You must agree to the Terms of Service, Privacy Policy, and Data Sharing Policies.');
      return;
    }
  
    try {
      // Step 1: Create user
      const signupResponse = await axios.post('https://api.transacly.co/signup', {
        ...loginInfo, 
        restrictedKey: stripeKey,  // Ensure restrictedKey is being sent here
        keyName: "Main Account"    // Optional: You can change the name as needed
      });
  
      if (signupResponse.status === 201) {
        const userId = signupResponse.data.userId;
        const token = signupResponse.data.token;
        setToken(token); // Store the token
  
        try {
          // Step 2: Save company information
          await axios.post('https://api.transacly.co/api/company-info', { userId, ...companyInfo }, {
            headers: {
              'Authorization': `Bearer ${token}` // Use the token
            }
          });
  
          navigate('/login');
        } catch (companyInfoError) {
          console.error('Error saving company information:', companyInfoError);
          setError('Error saving company information');
        }
      } else {
        setError('Error creating user');
      }
    } catch (signupError) {
      console.error('Error creating user:', signupError);
      if (signupError.response && signupError.response.data && signupError.response.data.message === 'Email already exists') {
        setError('Email already exists. Please use a different email.');
      } else {
        setError('Error creating user');
      }
    }
  };
  const getPasswordStrengthMessage = () => {
    switch (passwordStrength) {
      case 1:
        return 'Very weak';
      case 2:
        return 'Weak';
      case 3:
        return 'Moderate';
      case 4:
        return 'Strong';
      case 5:
        return 'Very strong';
      default:
        return '';
    }
  };

  if (!signupEnabled) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-100">
        <div className="bg-white p-6 rounded shadow-md w-full max-w-lg text-center">
          <h2 className="text-2xl mb-4">Signup to Transacly</h2>
          <p className="text-red-500 mb-4">
            Registration is temporarily disabled for now. If you have an account, please <a href="/login" className="text-blue-500">login here</a>.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <form onSubmit={handleSubmit} className="bg-white p-6 rounded shadow-md w-full max-w-lg">
        <h2 className="text-2xl mb-4 text-center">Signup to Transacly</h2>
        {error && <p className="text-red-500 mb-4">{error}</p>}
        <p className="mb-4 text-sm text-gray-600">Fields marked with <span className="text-red-500">*</span> are required.</p>
        <div className="mb-4">
        <div className="relative pt-1">
            <div className="flex mb-2 items-center justify-between">
              <div>
                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-green-600 bg-green-200">
                  Step {step} of 3
                </span>
              </div>
              <div className="text-right">
                <span className="text-xs font-semibold inline-block text-green-600">
                  {Math.round((step / 3) * 100)}%
                </span>
              </div>
            </div>
            <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-green-200">
              <div
                style={{ width: `${(step / 3) * 100}%` }}
                className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500">
              </div>
            </div>
          </div>
        </div>

        <SwitchTransition>
          <CSSTransition
            key={step}
            timeout={300}
            classNames={{
              enter: 'animate-slideInRight',
              exit: 'animate-slideOutLeft',
            }}
          >
            <div>
              {step === 1 && (
                <div className="step step1">
                  <input
                    type="text"
                    value={loginInfo.name}
                    onChange={(e) => setLoginInfo({ ...loginInfo, name: e.target.value })}
                    placeholder="Name *"
                    className="border p-2 mb-4 w-full"
                    autoComplete="name"
                  />
                  <input
                    type="email"
                    value={loginInfo.email}
                    onChange={(e) => setLoginInfo({ ...loginInfo, email: e.target.value })}
                    placeholder="Email *"
                    className="border p-2 mb-4 w-full"
                    autoComplete="email"
                  />
                  <input
                    type="password"
                    value={loginInfo.password}
                    onChange={handlePasswordChange}
                    placeholder="Password *"
                    className="border p-2 mb-4 w-full"
                    autoComplete="new-password"
                  />
                  <p className="text-sm text-gray-600 mb-4">
                    Password strength: {getPasswordStrengthMessage()}
                  </p>
                  <div className="flex justify-between">
                    <button type="button" className="bg-gray-500 text-white p-2 rounded" disabled>Back</button>
                    <button type="button" onClick={handleNextStep} className="bg-blue-500 text-white p-2 rounded">Next</button>
                  </div>
                </div>
              )}

{step === 2 && (
  <div className="step step2">
    {loading ? (
      <div className="flex flex-col items-center">
        <svg
          className="animate-spin h-8 w-8 text-blue-500 mb-4"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
          ></path>
        </svg>
        {validated && <span className="text-green-500 font-semibold">Stripe key validated!</span>}
      </div>
    ) : (
      <div>
        {/* Instructions for getting the Stripe Restricted Key */}
        <div className="mb-4 p-3 bg-gray-100 rounded">
          <p className="text-sm text-gray-700 mb-2">
            Please provide your Stripe Restricted API Key. You can generate a restricted key by visiting the{' '}
            <a
              href="https://dashboard.stripe.com/apikeys"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 underline"
            >
              Stripe API Keys Page
            </a>. 
            When creating the restricted key, make sure to enable the following permissions:
          </p>
          <ul className="list-disc list-inside text-sm text-gray-700">
            <li>Customers: Read, Write</li>
            <li>Charges: Write</li>
            <li>Invoices: Write</li>
            <li>Subscriptions: Write</li>
            <li>PaymentMethods: Write</li>
            <li>Products: Write</li>
            <li>Payouts: Write</li>
            <li>Events: Read</li>
            <li>Checkout Sessions: Write</li>
            <li>PaymentIntents: Write</li>
            <li>SetupIntents: Write</li>
            <li>Webhook Endpoints: Read</li>
          </ul>
        </div>

        {/* Stripe Restricted Key Field */}
        <input
          type="text"
          value={stripeKey}
          onChange={(e) => setStripeKey(e.target.value)}
          placeholder="Stripe Restricted Key *"
          className="border p-2 mb-4 w-full"
        />

        {/* Error message or missing permissions */}
        {error && <p className="text-red-500 mb-4">{error}</p>}
        {missingPermissions.length > 0 && (
          <div className="text-red-500 mb-4">
            <p>Your Stripe key is missing the following required permissions:</p>
            <ul className="list-disc list-inside">
              {missingPermissions.map((permission, index) => (
                <li key={index}>{permission}</li>
              ))}
            </ul>
          </div>
        )}

        <div className="flex justify-between">
          <button
            type="button"
            onClick={handlePrevStep}
            className="bg-gray-500 text-white p-2 rounded"
          >
            Back
          </button>
          <button
            type="button"
            onClick={handleNextStep}
            className="bg-blue-500 text-white p-2 rounded"
          >
            Validate
          </button>
        </div>
      </div>
    )}
  </div>
)}
              {step === 3 && (
                <div className="step step3">
                  <input
                    type="text"
                    value={companyInfo.company_name}
                    onChange={(e) => setCompanyInfo({ ...companyInfo, company_name: e.target.value })}
                    placeholder="Company Name *"
                    className="border p-2 mb-4 w-full"
                    autoComplete="organization"
                  />
                  <input
                    type="text"
                    value={companyInfo.address_line1}
                    onChange={(e) => setCompanyInfo({ ...companyInfo, address_line1: e.target.value })}
                    placeholder="House Number and Street Name *"
                    className="border p-2 mb-4 w-full"
                    autoComplete="address-line1"
                  />
                  <input
                    type="text"
                    value={companyInfo.address_line2}
                    onChange={(e) => setCompanyInfo({ ...companyInfo, address_line2: e.target.value })}
                    placeholder="Address Line 2"
                    className="border p-2 mb-4 w-full"
                    autoComplete="address-line2"
                  />
                  <input
                    type="text"
                    value={companyInfo.city}
                    onChange={(e) => setCompanyInfo({ ...companyInfo, city: e.target.value })}
                    placeholder="City *"
                    className="border p-2 mb-4 w-full"
                    autoComplete="address-level2"
                  />
                  <input
                    type="text"
                    value={companyInfo.state}
                    onChange={(e) => setCompanyInfo({ ...companyInfo, state: e.target.value })}
                    placeholder="State *"
                    className="border p-2 mb-4 w-full"
                    autoComplete="address-level1"
                  />
                  <input
                    type="text"
                    value={companyInfo.postal_code}
                    onChange={(e) => setCompanyInfo({ ...companyInfo, postal_code: e.target.value })}
                    placeholder="Postal Code *"
                    className="border p-2 mb-4 w-full"
                    autoComplete="postal-code"
                  />
                  <input
                    type="text"
                    value={companyInfo.country}
                    onChange={(e) => setCompanyInfo({ ...companyInfo, country: e.target.value })}
                    placeholder="Country *"
                    className="border p-2 mb-4 w-full"
                    autoComplete="country"
                  />
                  <input
                    type="text"
                    value={companyInfo.vat_number}
                    onChange={(e) => setCompanyInfo({ ...companyInfo, vat_number: e.target.value })}
                    placeholder="VAT Number (Optional)"
                    className="border p-2 mb-4 w-full"
                    autoComplete="off"
                  />
                  <div className="text-black-500 text-center p-3">
                    <label>
                      <input
                        type="checkbox"
                        checked={agreeToPolicies}
                        onChange={(e) => setAgreeToPolicies(e.target.checked)}
                        className="mr-2"
                      />
                      By clicking submit, you agree to all
                      <a href="https://transacly.co/policies" className="text-blue-400"> Terms of Service</a>,
                      <a href="https://transacly.co/policies" className="text-blue-400"> Privacy Policy</a>, and
                      <a href="https://transacly.co/policies" className="text-blue-400"> Data Sharing Policies</a>.
                    </label>
                  </div>
                  <div className="flex justify-between">
                    <button type="button"                     onClick={handlePrevStep} 
                    className="bg-gray-500 text-white p-2 rounded"
                    >
                      Back
                    </button>
                    <button 
                      type="submit" 
                      className="bg-blue-500 text-white p-2 rounded" 
                      disabled={!agreeToPolicies}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              )}
            </div>
          </CSSTransition>
        </SwitchTransition>

        <div className="text-gray-500 text-center p-3">
          If you already have an account, please login
          <a href="/login" className="text-blue-400"> HERE</a>.
        </div>
      </form>
    </div>
  );
}

export default Signup;