import React, { useState, useEffect } from 'react';
import axios from 'axios';
import NavigationBar from './NavigationBar';  // Assuming you have a NavigationBar component
import { FaSearch, FaSync, FaUserCircle } from 'react-icons/fa';

const Customers = () => {
  const [customers, setCustomers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [transactionHistory, setTransactionHistory] = useState([]);
  const [subscriptionData, setSubscriptionData] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);  // State for payment methods
  const [activeTab, setActiveTab] = useState('details');
  const [loadingCustomers, setLoadingCustomers] = useState(false);
  const [loadingDetails, setLoadingDetails] = useState(false);  // New state for loading details
  const [loadingTransactions, setLoadingTransactions] = useState(false);

  // Fetch customers when the component mounts
  useEffect(() => {
    fetchCustomers();
  }, []);

  // Fetch customers from the backend
  const fetchCustomers = async () => {
    setLoadingCustomers(true);
    try {
      const token = localStorage.getItem('token');  // Assuming token-based auth
      const response = await axios.get('https://api.transacly.co/api/customers', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCustomers(response.data);
      setLoadingCustomers(false);
    } catch (error) {
      console.error('Error fetching customers:', error);
      setLoadingCustomers(false);
    }
  };

  // Fetch details for the selected customer
  const fetchCustomerDetails = async (customerId) => {
    setLoadingDetails(true);  // Start loading for details
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`https://api.transacly.co/api/customer/${customerId}/details`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const { paymentMethods, subscriptions, charges } = response.data;

      setTransactionHistory(charges);
      setSubscriptionData(subscriptions);
      setPaymentMethods(paymentMethods);  // Set payment methods here
      setLoadingDetails(false);  // Stop loading after fetching details
    } catch (error) {
      console.error('Error fetching customer details:', error);
      setLoadingDetails(false);  // Stop loading in case of error
    }
  };

  // Handle customer selection
  const handleCustomerClick = (customer) => {
    setSelectedCustomer(customer);
    setActiveTab('details'); // Reset to details tab on new customer selection
    fetchCustomerDetails(customer.id);  // Fetch details (including payment methods) for the selected customer
  };

  // Filter customers based on the search term
  const filteredCustomers = customers.filter((customer) =>
    customer.email?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="min-h-screen">
      <NavigationBar />

      <div className="pt-20 md:ml-64 p-6">
        <div className="p-6 bg-white rounded-lg shadow-md mb-4 flex justify-between items-center">
          <h1 className="text-2xl font-semibold">Customers</h1>
          <div className="flex space-x-4">
            <div className="relative">
              <input
                type="text"
                className="border px-4 py-2 rounded-lg w-72"
                placeholder="Search by email..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <FaSearch className="absolute right-3 top-3 text-gray-500" />
            </div>
            <button
              className="bg-blue-500 text-white px-4 py-2 rounded-lg flex items-center"
              onClick={fetchCustomers}
            >
              <FaSync className="mr-2" /> Sync Customers
            </button>
          </div>
        </div>

        <div className="grid grid-cols-3 gap-4">
          {/* Customer list */}
          <div className="col-span-1 bg-white rounded-lg shadow-md p-4">
            {loadingCustomers ? (
              <p>Loading customers...</p>
            ) : (
              <ul>
                {filteredCustomers.map((customer) => (
                  <li
                    key={customer.id}
                    className="border-b py-2 cursor-pointer hover:bg-gray-100"
                    onClick={() => handleCustomerClick(customer)}
                  >
                    <FaUserCircle className="inline-block text-blue-500 mr-2" />
                    {customer.email}
                  </li>
                ))}
              </ul>
            )}
          </div>

          {/* Customer details and transactions */}
          <div className="col-span-2 bg-white rounded-lg shadow-md p-4 relative">
            {/* Loading bar for customer details */}
            {loadingDetails && (
              <div className="absolute top-0 left-0 right-0 h-1 bg-blue-500 animate-pulse"></div>
            )}

            {selectedCustomer ? (
              <div>
                <h2 className="text-xl font-semibold mb-4">{selectedCustomer.email}'s Profile</h2>

                {/* Tab Navigation */}
                <div className="flex border-b border-gray-200 mb-4">
                  <button
                    className={`px-4 py-2 mr-2 text-sm font-medium text-gray-700 ${activeTab === 'details' ? 'border-b-2 border-blue-500 text-blue-500' : 'hover:text-blue-500'}`}
                    onClick={() => setActiveTab('details')}
                  >
                    Details
                  </button>
                  <button
                    className={`px-4 py-2 mr-2 text-sm font-medium text-gray-700 ${activeTab === 'transactions' ? 'border-b-2 border-blue-500 text-blue-500' : 'hover:text-blue-500'}`}
                    onClick={() => setActiveTab('transactions')}
                  >
                    Transactions
                  </button>
                  <button
                    className={`px-4 py-2 mr-2 text-sm font-medium text-gray-700 ${activeTab === 'subscriptions' ? 'border-b-2 border-blue-500 text-blue-500' : 'hover:text-blue-500'}`}
                    onClick={() => setActiveTab('subscriptions')}
                  >
                    Subscriptions
                  </button>
                  <button
                    className={`px-4 py-2 text-sm font-medium text-gray-700 ${activeTab === 'payment-methods' ? 'border-b-2 border-blue-500 text-blue-500' : 'hover:text-blue-500'}`}
                    onClick={() => setActiveTab('payment-methods')}
                  >
                    Payment Methods
                  </button>
                </div>

                {/* Tab Content */}
                <div className="tab-content">
                  {activeTab === 'details' && (
                    <div className="details">
                      <p><strong>ID:</strong> {selectedCustomer.id}</p>
                      <p><strong>Name:</strong> {selectedCustomer.name || 'N/A'}</p>
                      <p><strong>Phone:</strong> {selectedCustomer.phone || 'N/A'}</p>
                    </div>
                  )}

                  {activeTab === 'transactions' && (
                    <div className="transactions">
                      <h3 className="text-lg font-semibold">Transaction History</h3>
                      {loadingTransactions ? (
                        <p>Loading transactions...</p>
                      ) : transactionHistory.length ? (
                        <ul>
                          {transactionHistory.map((transaction) => (
                            <li key={transaction.id} className="border-b py-2">
                              <p><strong>Amount:</strong> ${(transaction.amount / 100).toFixed(2)} {transaction.currency.toUpperCase()}</p>
                              <p><strong>Date:</strong> {new Date(transaction.created * 1000).toLocaleDateString()}</p>
                              <p><strong>Status:</strong> {transaction.status}</p>
                              <p><strong>Description:</strong> {transaction.description || 'N/A'}</p>
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <p>No transactions available for this customer.</p>
                      )}
                    </div>
                  )}

                  {activeTab === 'subscriptions' && (
                    <div className="subscriptions">
                      <h3 className="text-lg font-semibold">Subscriptions</h3>
                      {subscriptionData.length ? (
                        <ul>
                          {subscriptionData.map((subscription) => (
                            <li key={subscription.id} className="border-b py-2">
                              <p><strong>Plan:</strong> {subscription.plan?.nickname || 'N/A'}</p>
                              <p><strong>Status:</strong> {subscription.status}</p>
                              <p><strong>Start Date:</strong> {new Date(subscription.start_date * 1000).toLocaleDateString()}</p>
                              <p><strong>Next Billing Date:</strong> {new Date(subscription.current_period_end * 1000).toLocaleDateString()}</p>
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <p>No subscriptions available for this customer.</p>
                      )}
                    </div>
                  )}

                  {activeTab === 'payment-methods' && (
                    <div className="payment-methods">
                      <h3 className="text-lg font-semibold">Payment Methods</h3>
                      {paymentMethods.length ? (
                        <ul>
                          {paymentMethods.map((method) => (
                            <li key={method.id} className="border-b py-2">
                              <p><strong>Card Brand:</strong> {method.card.brand.toUpperCase()}</p>
                              <p><strong>Last 4 Digits:</strong> **** **** **** {method.card.last4}</p>
                              <p><strong>Expiration:</strong> {method.card.exp_month}/{method.card.exp_year}</p>
                              <p><strong>Billing Details:</strong> {method.billing_details.name || 'N/A'}, {method.billing_details.email || 'N/A'}</p>
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <p>No payment methods available for this customer.</p>
                      )}
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <p>Select a customer to view details.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Customers;