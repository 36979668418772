import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './components/Login';
import Signup from './components/Signup';
import Dashboard from './components/Dashboard';
import Invoice from './components/Invoice';
import Plans from './components/Plans';
import Success from './components/Success';
import SuccessTrans from './components/SuccessTrans';
import ProductManagement from './components/ProductManagement';
import InvoiceList from './components/InvoiceList';
import InvoiceDetail from './components/InvoiceDetail'; // Ensure this is the correct path
import HomePage from './components/HomePage';
import SupportPage from './components/SupportPage';
import SubView from './components/subView';
import { Analytics } from '@vercel/analytics/react';
import Security from './components/security';
import Policies from './components/Policies';
import Customers from './components/Customers';
import RedirectToCheckout from './components/RedirectToCheckout';
import Error from './components/errorPage';
import AnalyticsView from './components/Analytics';

function App() {
  return (
    <Router>
      <Analytics />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/invoice" element={<Invoice />} />
        <Route path="/plans" element={<Plans />} />
        <Route path="/success" element={<Success />} />
        <Route path="/productManagement" element={<ProductManagement />} />
        <Route path="/invoiceList" element={<InvoiceList />} />
        <Route path="/invoice/:invoiceUURL" element={<InvoiceDetail />} /> {/* Make sure InvoiceDetail uses useParams */}
        <Route path="/successTransacly" element={<SuccessTrans />} />
        <Route path="/" element={<HomePage />} />
        <Route path="/support" element={<SupportPage />} /> {/* Make sure to match the case sensitivity */}
        <Route path="/subscriptions" element={<SubView />} /> {/* Make sure to match the case sensitivity */}
        <Route path="/security" element={<Security />} />
        <Route path="/policies" element={<Policies />} />
        <Route path="/customers" element={<Customers />} />
        <Route path="/link/:uniqueId" element={<RedirectToCheckout />} />
        <Route path="*" element={<Error />} />
        <Route path="/Analytics" element={<AnalyticsView />} />
      </Routes>
    </Router>
  );
}
export default App;