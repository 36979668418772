import React, { useEffect, useState } from 'react';
import axios from 'axios';
import DashGraph from '../Modules/dash-graph';
import { useNavigate } from 'react-router-dom';
import NavigationBar from './NavigationBar';
import { FaInfoCircle } from 'react-icons/fa'; // Importing info icon
import Tooltip from '../Modules/Tooltip'; // Assuming you have or will create a Tooltip component

function Analytics() {
    const navigate = useNavigate();
    const [analyticsData, setAnalyticsData] = useState({});
    const [loading, setLoading] = useState(true);

    // Handle logout
    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/login');
    };

    useEffect(() => {
        const fetchAnalytics = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get('https://api.transacly.co/api/analytics', {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setAnalyticsData(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching analytics data:', error);
                setLoading(false);
            }
        };

        fetchAnalytics();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="min-h-screen">
            {/* Sidebar with logout functionality */}
            <NavigationBar handleLogout={handleLogout} />

            {/* Main content area - shifted by 16rem to accommodate the sidebar */}
            <div className="pt-20 md:ml-64 p-6">
                <h1 className="text-2xl font-bold mb-4">Analytics Overview</h1>

                {/* Stats Overview - Monthly Recurring Revenue, Projected MRR, Payments */}
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8">
                    <div className="bg-white p-6 rounded-lg shadow-md relative">
                        <h2 className="text-lg font-semibold flex items-center">
                            Monthly Recurring Revenue (MRR)
                            <Tooltip text="MRR is the predictable revenue you can expect every month from active subscriptions." />
                        </h2>
                        <p className="text-2xl">${analyticsData.MRR}</p>
                    </div>

                    <div className="bg-white p-6 rounded-lg shadow-md relative">
                        <h2 className="text-lg font-semibold flex items-center">
                            Projected MRR (Next Month)
                            <Tooltip text="Projected MRR estimates your recurring revenue for the next month based on current subscriptions." />
                        </h2>
                        <p className="text-2xl">${analyticsData.projectedMRR}</p>
                    </div>

                    <div className="bg-white p-6 rounded-lg shadow-md relative">
                        <h2 className="text-lg font-semibold flex items-center">
                            Total Payments
                            <Tooltip text="Total payments include all the transactions successfully processed on your platform." />
                        </h2>
                        <p className="text-2xl">${analyticsData.totalPayments}</p>
                    </div>

                    <div className="bg-white p-6 rounded-lg shadow-md relative">
                        <h2 className="text-lg font-semibold flex items-center">
                            Failed Payment Rate
                            <Tooltip text="The percentage of payments that were attempted but failed." />
                        </h2>
                        <p className="text-2xl">{analyticsData.failedPaymentRate}%</p>
                    </div>

                    <div className="bg-white p-6 rounded-lg shadow-md relative">
                        <h2 className="text-lg font-semibold flex items-center">
                            Average Revenue Per User (ARPU)
                            <Tooltip text="ARPU represents the average monthly revenue generated per active user." />
                        </h2>
                        <p className="text-2xl">${analyticsData.ARPU}</p>
                    </div>

                    <div className="bg-white p-6 rounded-lg shadow-md relative">
                        <h2 className="text-lg font-semibold flex items-center">
                            Churn Rate
                            <Tooltip text="Churn rate indicates the percentage of customers that cancel or do not renew their subscriptions." />
                        </h2>
                        <p className="text-2xl">{analyticsData.churnRate}%</p>
                    </div>
                </div>

                {/* Revenue Over Time Graph */}
                <div className="bg-white p-6 rounded-lg shadow-md">
                    <h2 className="text-lg font-semibold mb-4">Revenue Over Time</h2>
                    {/* Use DashGraph component and pass relevant data */}
                    <DashGraph dailyTotals={analyticsData.dailyTotals} />
                </div>
            </div>
        </div>
    );
}

export default Analytics;