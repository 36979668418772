// Notification.js
import React from 'react';

const Notification = ({ message, type, onClose }) => {
  if (!message) return null;

  return (
    <div
      className={`fixed top-0 left-1/2 transform -translate-x-1/2 mt-4 px-4 py-2 rounded shadow-lg ${
        type === 'success' ? 'bg-green-500 text-white' : 'bg-red-500 text-white'
      }`}
    >
      <span>{message}</span>
      <button
        className="ml-4 text-lg font-bold"
        onClick={onClose}
      >
        &times;
      </button>
    </div>
  );
};

export default Notification;