import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import NavigationBar from './NavigationBar';
import PMGrid from '../Modules/PM-Grid'; // Import the PMGrid component
import PMCreateForm from '../Modules/PM-Create-form';
import ReactPaginate from 'react-paginate';
import { FaSync } from 'react-icons/fa'; // Import Sync icon
import  Notification  from '../Modules/Notification';
import Modal from '../Modules/PM-Modal';

const ProductManagement = () => {
  const navigate = useNavigate();
  const [dropdowns, setDropdowns] = useState({});
  const [products, setProducts] = useState([]);
  const [currentProducts, setCurrentProducts] = useState([]);
  const [newProduct, setNewProduct] = useState({
    name: '',
    description: '',
    price: '',
    currency: 'usd',
    interval: 'month',
    taxCode: '',
  });
  const [taxCodes, setTaxCodes] = useState([]);
  const [editingProduct, setEditingProduct] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [pageCount, setPageCount] = useState(0);
  const productsPerPage = 10;
  const [showInvoiceModal, setShowInvoiceModal] = useState(false);
  const [invoiceDetails, setInvoiceDetails] = useState({
    customerName: '',
    customerEmail: '',
    customerAddress: '',
    selectedProducts: [],
  });
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [productToDelete, setProductToDelete] = useState(null);
  const [deleteProductName, setDeleteProductName] = useState('');
  const [errors, setErrors] = useState({}); // Track validation errors
  const [notification, setNotification] = useState({ message: '', type: '' }); // Notification state
  const [showModal, setShowModal] = useState(false); // Modal state
  const [generatedLink, setGeneratedLink] = useState(''); // This will hold the generated link

  const stripeAcceptedCurrencies = [
    'usd', 'eur', 'gbp', 'aud', 'cad', 'nzd', 'jpy', 'sgd', 'hkd', 'sek',
    'nok', 'dkk', 'chf', 'zar', 'mxn', 'brl', 'inr', 'myr', 'php', 'idr',
    'thb', 'vnd', 'cny', 'krw', 'ils', 'aed', 'sar', 'pln', 'czk', 'huf'
  ];

  useEffect(() => {
    fetchProducts();
    fetchTaxCodes();
  }, []);

  const fetchProducts = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get('https://api.transacly.co/api/products', {
        headers: { Authorization: `Bearer ${token}` },
      });
      const products = response.data.reverse();
      setProducts(products);
      setPageCount(Math.ceil(products.length / productsPerPage));
      setCurrentProducts(products.slice(0, productsPerPage));
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  const fetchTaxCodes = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get('https://api.transacly.co/api/stripe-tax-codes', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setTaxCodes(response.data);
    } catch (error) {
      console.error('Error fetching tax codes:', error);
    }
  };

  const handlePageClick = (data) => {
    const selectedPage = data.selected;
    const offset = selectedPage * productsPerPage;
    setCurrentProducts(products.slice(offset, offset + productsPerPage));
  };

  const handleCreateProduct = async () => {
    const token = localStorage.getItem('token');
    const newErrors = {};
    if (!newProduct.name) newErrors.name = 'Name is required';
    if (!newProduct.price) newErrors.price = 'Price is required';
    if (!newProduct.taxCode) newErrors.taxCode = 'Tax Code is required';

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
      const response = await axios.post('https://api.transacly.co/api/create-product', newProduct, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setProducts([response.data, ...products]);
      setPageCount(Math.ceil((products.length + 1) / productsPerPage));
      setCurrentProducts([response.data, ...products].slice(0, productsPerPage));
      setNewProduct({ name: '', description: '', price: '', currency: 'usd', interval: 'month', taxCode: '' });
      setShowCreateForm(false);
      setErrors({});
    } catch (error) {
      console.error('Error creating product:', error);
    }
  };


  const handleGenerateLink = async (product) => {
    const token = localStorage.getItem('token');
  
    try {
      const response = await axios.post(
        'https://api.transacly.co/api/generate-stripe-link',
        { productId: product.product_id },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
  
      const { checkoutUrl } = response.data;

      // Set the generated link and open the modal
      setGeneratedLink(checkoutUrl);
      setShowModal(true);
  
    } catch (error) {
      console.error('Error generating purchase link:', error);
      showNotification('Error generating purchase link', 'error');
    }
  };


  const handleEditClick = (product) => {
    // Ensure all fields, including taxCode, are initialized correctly
    setEditingProduct({
      ...product,
      taxCode: product.taxCode || '' // Make sure taxCode is set correctly
    });
    setShowEditModal(true); // Show the edit modal
  };
  const handleUpdateProduct = async () => {
    const newErrors = {};
  
    // Validate required fields
    if (!editingProduct.name.trim()) newErrors.name = 'Name is required';
    if (!editingProduct.price) newErrors.price = 'Price is required';
    if (!editingProduct.taxCode.trim()) newErrors.taxCode = 'Tax Code is required'; // Ensure tax code is not blank
  
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
  
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post('https://api.transacly.co/api/update-product', {
        productId: editingProduct.product_id,
        name: editingProduct.name,
        description: editingProduct.description,
        price: editingProduct.price,
        currency: editingProduct.currency,
        interval: editingProduct.interval,
        taxCode: editingProduct.taxCode, // Send the correct tax code
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      const updatedProducts = products.map((p) => (p.product_id === editingProduct.product_id ? response.data : p));
      setProducts(updatedProducts);
      setCurrentProducts(updatedProducts.slice(0, productsPerPage));
      setShowEditModal(false);
      setErrors({});
    } catch (error) {
      console.error('Error updating product:', error);
    }
  };

  const handleShowDeleteModal = (product) => {
    setProductToDelete(product);
    setShowDeleteModal(true);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  const generateInvoice = async () => {
    const token = localStorage.getItem('token');
    const { customerName, customerEmail, customerAddress, selectedProducts } = invoiceDetails;
  
    if (!customerName || !customerEmail || !customerAddress) {
     showNotification("All fields are required to generate the invoice.");
      return;
    }
  
    try {
      const response = await axios.post('https://transacly.co/api/generate-product-invoice', {
        customerName,
        customerEmail,
        customerAddress,
        selectedProducts,
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      const { invoiceId } = response.data;
      // Redirect to the invoice page or display a success message
      navigate('/invoice', { state: { invoiceId } });
    } catch (error) {
      console.error('Error generating product invoice:', error);
      alert('Error generating product invoice');
    }
  };
  // New Function: toggleDropdown - Toggles the dropdown menu for each product
  const toggleDropdown = (productId) => {
    setDropdowns((prev) => ({
      ...prev,
      [productId]: !prev[productId], // Toggle the dropdown visibility for the specific product
    }));
  };

  // New Function: openInvoiceModal - Opens the modal for generating an invoice
  const openInvoiceModal = (product) => {
    setInvoiceDetails((prevDetails) => ({
      ...prevDetails,
      selectedProducts: [product.product_id], // Set the selected product for invoicing
    }));
    setShowInvoiceModal(true); // Show the invoice modal
  };
  // New Function: handleDeleteProduct - Handles the deletion of a product
  const handleDeleteProduct = async () => {
    const token = localStorage.getItem('token');
    try {
      await axios.post('https://api.transacly.co/api/delete-product', { productId: productToDelete.product_id }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const updatedProducts = products.filter((p) => p.product_id !== productToDelete.product_id);
      setProducts(updatedProducts);
      setPageCount(Math.ceil(updatedProducts.length / productsPerPage));
      setCurrentProducts(updatedProducts.slice(0, productsPerPage));
      setShowDeleteModal(false);
      setProductToDelete(null);
      setDeleteProductName('');
    } catch (error) {
      console.error('Error deleting product:', error);
    }
  };
  const syncProductsWithStripe = async () => {
    const token = localStorage.getItem('token');
    
    console.log('Attempting to sync products with Stripe...'); // Add logging here
    
    try {
      const response = await axios.post('https://api.transacly.co/api/sync-products', {}, {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      console.log('Sync response from backend:', response.data); // Log the response
  
      showNotification(response.data.message);
      fetchProducts(); // Re-fetch the products after syncing
    } catch (error) {
      console.error('Error syncing products:', error);
      
      // Show specific permission error message if available
      if (error.response && error.response.data && error.response.data.message.includes('Permission error')) {
        showNotification('Error syncing products: Missing required permissions.', 'error');
      } else {
        showNotification('Error syncing products', 'error');
      }
    }
  };

  const showNotification = (message, type) => {
    setNotification({ message, type });

    // Automatically hide notification after 3 seconds
    setTimeout(() => {
      setNotification({ message: '', type: '' });
    }, 3000);
  };

  return (
    <div className="min-h-screen">
      <NavigationBar handleLogout={handleLogout}/>
      {showModal && <Modal link={generatedLink} onClose={() => setShowModal(false)} />}
      {/* Notification Component */}
      <Notification
        message={notification.message}
        type={notification.type}
        onClose={() => setNotification({ message: '', type: '' })}
      />

      {/* Main content area - shifted by 16rem to accommodate the sidebar */}
      <div className="pt-20 md:ml-64 p-6">
      <div className={`p-6 rounded shadow-md mb-4 flex justify-between items-center`}>
          <h3 className="text-xl">Product Management</h3>
          <div className="flex items-center">
            {/* Create New Product Button */}
            <button
              onClick={() => setShowCreateForm(!showCreateForm)}
              className="bg-green-500 text-white p-2 rounded-full transition-transform transform hover:scale-105 mr-2"
            >
              {showCreateForm ? (
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" /> {/* X Icon */}
                </svg>
              ) : (
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4" /> {/* Plus Icon */}
                </svg>
              )}
            </button>
            {/* Sync with Stripe Button */}
            <button
              onClick={syncProductsWithStripe}
              className="bg-blue-500 text-white p-2 rounded-full transition-transform transform hover:scale-105"
              title="Sync with Stripe"
            >
              <FaSync className="w-6 h-6" />
            </button>
          </div>
        </div>
        {/* Render Create Form if the user toggles it */}
        {showCreateForm && (
          <PMCreateForm
            newProduct={newProduct}
            setNewProduct={setNewProduct}
            handleCreateProduct={handleCreateProduct}
            isDarkMode={isDarkMode}
            errors={errors}
            stripeAcceptedCurrencies={stripeAcceptedCurrencies}
            taxCodes={taxCodes}
            handleGenerateLink={handleGenerateLink}
          />
        )}

        {/* Product Grid */}
        <PMGrid
          currentProducts={currentProducts}
          isDarkMode={isDarkMode}
          toggleDropdown={toggleDropdown}
          dropdowns={dropdowns}
          handleEditClick={handleEditClick}
          handleShowDeleteModal={handleShowDeleteModal}
          openInvoiceModal={openInvoiceModal}
          handleGenerateLink={handleGenerateLink} // <-- Pass it here
        />

        <div className="flex justify-between items-center mt-4">
          <ReactPaginate
            previousLabel={'Previous'}
            nextLabel={'Next'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName={'pagination flex justify-center'}
            pageClassName={'page-item'}
            pageLinkClassName={`page-link px-3 py-1 rounded border ${isDarkMode ? 'border-gray-600' : 'border-gray-300'}`}
            previousClassName={'page-item'}
            previousLinkClassName={`page-link px-3 py-1 rounded border ${isDarkMode ? 'border-gray-600' : 'border-gray-300'}`}
            nextClassName={'page-item'}
            nextLinkClassName={`page-link px-3 py-1 rounded border ${isDarkMode ? 'border-gray-600' : 'border-gray-300'}`}
            activeClassName={'active'}
            activeLinkClassName={'bg-blue-500 text-white'}
          />
        </div>

        {/* Edit Product Modal */}
        {showEditModal && editingProduct && (
          <div className="fixed z-50 inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
            <div className={`bg-white p-6 rounded shadow-md max-w-lg w-full ${isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-black'}`}>
              <h4 className="text-xl mb-4 text-center font-semibold underline">Edit Product</h4>
              <div>
                <label className="block text-sm font-medium text-gray-700">Name</label>
                <input
                  type="text"
                  name="name"
                  value={editingProduct.name}
                  onChange={(e) => setEditingProduct({ ...editingProduct, name: e.target.value })}
                  className={`border p-2 rounded mb-2 w-full ${isDarkMode ? 'bg-gray-700 border-gray-600 text-white' : 'border-gray-300'}`}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Description</label>
                <textarea
                  name="description"
                  value={editingProduct.description}
                  onChange={(e) => setEditingProduct({ ...editingProduct, description: e.target.value })}
                  className={`border p-2 rounded mb-2 w-full ${isDarkMode ? 'bg-gray-700 border-gray-600 text-white' : 'border-gray-300'}`}
                  rows="3"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Price</label>
                <input
                  type="number"
                  name="price"
                  value={editingProduct.price}
                  onChange={(e) => setEditingProduct({ ...editingProduct, price: e.target.value })}
                  className={`border p-2 rounded mb-2 w-full ${isDarkMode ? 'bg-gray-700 border-gray-600 text-white' : 'border-gray-300'}`}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Currency</label>
                <select
                  name="currency"
                  value={editingProduct.currency}
                  onChange={(e) => setEditingProduct({ ...editingProduct, currency: e.target.value })}
                  className={`border p-2 rounded mb-2 w-full bg-white ${isDarkMode ? 'bg-gray-700 border-gray-600 text-white' : 'border-gray-300'}`}
                >
                  {stripeAcceptedCurrencies.map((currency) => (
                    <option key={currency} value={currency}>
                      {currency.toUpperCase()}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Interval</label>
                <select
                  name="interval"
                  value={editingProduct.interval}
                  onChange={(e) => setEditingProduct({ ...editingProduct, interval: e.target.value })}
                  className={`border p-2 rounded mb-2 w-full bg-white ${isDarkMode ? 'bg-gray-700 border-gray-600 text-white' : 'border-gray-300'}`}
                >
                  <option value="day">Daily</option>
                  <option value="week">Weekly</option>
                  <option value="month">Monthly</option>
                  <option value="year">Yearly</option>
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Tax Code</label>
                <select
                  name="taxCode"
                  value={editingProduct.taxCode}
                  onChange={(e) => setEditingProduct({ ...editingProduct, taxCode: e.target.value })}
                  className={`border p-2 rounded mb-2 w-full bg-white ${isDarkMode ? 'bg-gray-700 border-gray-600 text-white' : 'border-gray-300'}`}
                >
                  <option value="">Select a tax code</option>
                  {taxCodes.map((code) => (
                    <option key={code.id} value={code.id}>
                      {code.name}
                    </option>
                  ))}
                </select>
                {errors.taxCode && <span className="text-red-500 text-sm">{errors.taxCode}</span>}
              </div>
              <div className="flex justify-end mt-4">
                <button
                  onClick={handleUpdateProduct}
                  className="bg-blue-500 text-white px-4 py-2 rounded transition-transform transform hover:scale-105"
                >
                  Save Changes
                </button>
                <button
                  onClick={() => setShowEditModal(false)}
                  className="bg-gray-500 text-white px-4 py-2 ml-2 rounded transition-transform transform hover:scale-105"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
        {showInvoiceModal && (
          <div className="fixed z-50 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen px-4">
              <div className={`bg-white p-6 rounded shadow-md max-w-lg w-full ${isDarkMode ? 'bg-gray-800' : 'bg-white text-black'}`}>
                <h4 className="text-xl mb-4 text-center font-semibold underline">Generate Invoice</h4>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Customer Name</label>
                  <input
                    type="text"
                    name="customerName"
                    value={invoiceDetails.customerName}
                    onChange={(e) => setInvoiceDetails({ ...invoiceDetails, customerName: e.target.value })}
                    className={`border p-2 rounded mb-2 w-full ${isDarkMode ? 'bg-gray-700 border-gray-600 text-white' : 'border-gray-300'}`}
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Customer Email</label>
                  <input
                    type="email"
                    name="customerEmail"
                    value={invoiceDetails.customerEmail}
                    onChange={(e) => setInvoiceDetails({ ...invoiceDetails, customerEmail: e.target.value })}
                    className={`border p-2 rounded mb-2 w-full ${isDarkMode ? 'bg-gray-700 border-gray-600 text-white' : 'border-gray-300'}`}
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Customer Address</label>
                  <input
                    type="text"
                    name="customerAddress"
                    value={invoiceDetails.customerAddress}
                    onChange={(e) => setInvoiceDetails({ ...invoiceDetails, customerAddress: e.target.value })}
                    className={`border p-2 rounded mb-2 w-full ${isDarkMode ? 'bg-gray-700 border-gray-600 text-white' : 'border-gray-300'}`}
                  />
                </div>
                <div className="flex justify-end mt-4">
                  <button
                    onClick={generateInvoice}
                    className="bg-green-500 text-white px-4 py-2 rounded transition-transform transform hover:scale-105"
                  >
                    Generate Invoice
                  </button>
                  <button
                    onClick={() => setShowInvoiceModal(false)}
                    className="bg-gray-500 text-white px-4 py-2 ml-2 rounded transition-transform transform hover:scale-105"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* Delete Confirmation Modal */}
        {showDeleteModal && (
          <div className="fixed z-50 inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
            <div className={`bg-white p-6 rounded shadow-md max-w-lg w-full ${isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-black'}`}>
              <h4 className="text-xl mb-4 text-center font-semibold underline">Confirm Delete</h4>
              <p className="mb-4">
                Please type <strong>{productToDelete?.name}</strong> to confirm deletion.
              </p>
              <input
                type="text"
                value={deleteProductName}
                onChange={(e) => setDeleteProductName(e.target.value)}
                className={`border p-2 rounded mb-4 w-full ${isDarkMode ? 'bg-gray-700 border-gray-600 text-white' : 'border-gray-300'}`}
              />
              <div className="flex justify-end">
                <button
                  onClick={() => {
                    if (deleteProductName === productToDelete?.name) {
                      handleDeleteProduct();
                    } else {
                      alert('Product name does not match. Please type the correct product name to confirm.');
                    }
                  }}
                  className="bg-red-500 text-white px-4 py-2 rounded transition-transform transform hover:scale-105"
                >
                  Delete
                </button>
                <button
                  onClick={() => {
                    setShowDeleteModal(false);
                    setProductToDelete(null);
                    setDeleteProductName('');
                  }}
                  className="bg-gray-500 text-white px-4 py-2 ml-2 rounded transition-transform transform hover:scale-105"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductManagement;