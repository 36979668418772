import React from 'react';

const Policies = () => {
  return (
    <div className="min-h-screen bg-gray-100">
      
      <div className="pt-20 p-6 max-w-7xl mx-auto">
        <h1 className="text-4xl font-bold mb-8">Policies</h1>
        
        {/* Privacy Policy */}
        <section className="mb-12">
  <h2 className="text-3xl font-semibold mb-4">Privacy Policy</h2>
  <p><em>Last Updated: 01/10/2024</em></p>
  <p>At <strong>Transacly.co</strong> ("we," "us," or "our"), we are committed to protecting and respecting your privacy. This Privacy Policy outlines how we collect, use, disclose, and safeguard your personal data when you visit our website or use our services, in accordance with the General Data Protection Regulation (GDPR) and other applicable laws.</p>
  <p>By accessing or using our services, you agree to the collection and use of information in accordance with this policy.</p>
  
  <h3 className="text-xl font-semibold mt-6">1. Data Controller</h3>
  <p><strong>Transacly.co</strong> is the data controller responsible for your personal data.</p>
  <p><strong>Contact Information:</strong></p>
  <ul className="list-disc ml-6">
    <li><strong>Email:</strong> <a href="mailto:support@proxitech.co.uk">support@proxitech.co.uk</a></li>
    <li><strong>Postal Address:</strong> Hexagon House, Avenue Four, Witney, England, OX28 4BN</li>
  </ul>
  
  <h3 className="text-xl font-semibold mt-6">2. Data We Collect</h3>
  <p>We may collect and process the following types of personal data:</p>
  <ul className="list-disc ml-6">
    <li><strong>Identity Data:</strong> First name, last name, username, or similar identifiers.</li>
    <li><strong>Contact Data:</strong> Email address and telephone numbers.</li>
    <li><strong>Financial Data:</strong> Payment card details and transaction history. <em>Note: Payment information is processed by our third-party payment processor, Stripe. We do not store sensitive payment data on our servers.</em></li>
    <li><strong>Technical Data:</strong> IP address, browser type and version, time zone setting, browser plug-in types and versions, operating system, and platform.</li>
    <li><strong>Usage Data:</strong> Information about how you use our website, products, and services.</li>
    <li><strong>Marketing and Communications Data:</strong> Your preferences in receiving marketing from us and your communication preferences.</li>
  </ul>
  
  <h3 className="text-xl font-semibold mt-6">3. How We Collect Your Data</h3>
  <p>We use different methods to collect data from and about you, including:</p>
  <ul className="list-disc ml-6">
    <li><strong>Direct Interactions:</strong> Filling out forms or corresponding with us via email or phone.</li>
    <li><strong>Automated Technologies:</strong> Collecting Technical Data as you interact with our website through cookies and similar technologies.</li>
    <li><strong>Third Parties:</strong> Receiving data from third-party services like analytics providers (e.g., Google Analytics), advertising networks, and payment processors.</li>
  </ul>
  
  <h3 className="text-xl font-semibold mt-6">4. How We Use Your Data</h3>
  <p>We use your personal data for the following purposes:</p>
  <ul className="list-disc ml-6">
    <li><strong>Service Delivery:</strong> To provide and maintain our services.</li>
    <li><strong>Account Management:</strong> To manage your registration as a user.</li>
    <li><strong>Transaction Processing:</strong> To process payments and manage subscriptions.</li>
    <li><strong>Communication:</strong> To contact you regarding your account or to send service-related announcements.</li>
    <li><strong>Improvement:</strong> To analyze data so we can improve our services and user experience.</li>
    <li><strong>Legal Obligations:</strong> To comply with legal requirements and enforce our terms and policies.</li>
  </ul>
  
  <h3 className="text-xl font-semibold mt-6">5. Legal Basis for Processing</h3>
  <p>Under GDPR, we rely on the following legal bases for processing your personal data:</p>
  <ul className="list-disc ml-6">
    <li><strong>Consent:</strong> Where you have given clear consent.</li>
    <li><strong>Contractual Necessity:</strong> Processing necessary for the performance of a contract with you.</li>
    <li><strong>Legal Obligation:</strong> Compliance with a legal obligation.</li>
    <li><strong>Legitimate Interests:</strong> Processing necessary for our legitimate interests or those of a third party, provided your rights do not override those interests.</li>
  </ul>
  
  <h3 className="text-xl font-semibold mt-6">6. Data Sharing and Disclosure</h3>
  <p>We may share your personal data with:</p>
  <ul className="list-disc ml-6">
    <li><strong>Service Providers:</strong> Third-party companies that perform services on our behalf, such as:
      <ul className="list-disc ml-6">
        <li><strong>Stripe:</strong> For payment processing.</li>
        <li><strong>Vercel:</strong> For hosting the platform.</li>
        <li><strong>Cloudflare:</strong> For proxy services and security.</li>
      </ul>
    </li>
    <li><strong>Legal Authorities:</strong> If required by law or to protect our rights.</li>
    <li><strong>Business Transfers:</strong> In the event of a merger, acquisition, or asset sale.</li>
  </ul>
  
  <h3 className="text-xl font-semibold mt-6">7. International Data Transfers</h3>
  <p>Your data may be transferred to and processed in countries outside of your own, including countries outside the European Economic Area (EEA). We ensure that appropriate safeguards are in place to protect your data in accordance with this Privacy Policy.</p>
  
  <h3 className="text-xl font-semibold mt-6">8. Data Retention</h3>
  <p>We will retain your personal data only for as long as necessary to fulfill the purposes we collected it for, including satisfying any legal, accounting, or reporting requirements.</p>
  
  <h3 className="text-xl font-semibold mt-6">9. Your Rights Under GDPR</h3>
  <p>Under GDPR, you have the following rights regarding your personal data:</p>
  <ul className="list-disc ml-6">
    <li><strong>Right to Access:</strong> Obtain confirmation that your data is being processed and access your personal data.</li>
    <li><strong>Right to Rectification:</strong> Request correction of inaccurate or incomplete data.</li>
    <li><strong>Right to Erasure:</strong> Request deletion of your personal data under certain conditions.</li>
    <li><strong>Right to Restrict Processing:</strong> Request to restrict the processing of your data under certain circumstances.</li>
    <li><strong>Right to Data Portability:</strong> Receive your personal data in a structured, commonly used format.</li>
    <li><strong>Right to Object:</strong> Object to the processing of your data for certain purposes.</li>
    <li><strong>Rights Related to Automated Decision-Making:</strong> Not to be subject to a decision based solely on automated processing.</li>
  </ul>
  <p>To exercise any of these rights, please contact us at <a href="mailto:[your-email@example.com]">[your-email@example.com]</a>.</p>
  
  <h3 className="text-xl font-semibold mt-6">10. Cookies and Tracking Technologies</h3>
  <p>We use cookies and similar tracking technologies to enhance your experience. You can manage your cookie preferences through your browser settings.</p>
  
  <h3 className="text-xl font-semibold mt-6">11. Data Security</h3>
  <p>We implement appropriate technical and organizational measures to protect your personal data against unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the Internet is entirely secure.</p>
  
  <h3 className="text-xl font-semibold mt-6">12. Children's Privacy</h3>
  <p>Our services are not intended for individuals under the age of 16. We do not knowingly collect personal data from children under 16. If we become aware that we have collected such data, we will take steps to delete it.</p>
  
  <h3 className="text-xl font-semibold mt-6">13. Changes to This Privacy Policy</h3>
  <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated revision date. We encourage you to review this policy periodically.</p>
  
  <h3 className="text-xl font-semibold mt-6">14. Contact Us</h3>
  <p>If you have any questions about this Privacy Policy or our data practices, please contact us:</p>
  <ul className="list-disc ml-6">
    <li><strong>Email:</strong> <a href="mailto:support@proxitech.co.uk">support@proxitech.co.uk</a></li>
    <li><strong>Postal Address:</strong> Hexagon House, Avenue Four, Witney, England, OX28 4BN</li>
  </ul>
  
  <h3 className="text-xl font-semibold mt-6">15. Right to Lodge a Complaint</h3>
  <p>If you believe that our processing of your personal data infringes data protection laws, you have the right to lodge a complaint with a supervisory authority, particularly in the EU member state of your habitual residence, place of work, or place of the alleged infringement.</p>
  
</section>

        {/* Terms and Conditions */}
        <section className="mb-12">
  <h2 className="text-3xl font-semibold mb-4">Terms and Conditions</h2>
  <p><em>Last Updated: 01/10/2024</em></p>
  
  <h3 className="text-xl font-semibold mt-6">1. Acceptance of Terms</h3>
  <p>By accessing or using the services provided by <strong>Transacly.co</strong> ("we," "us," or "our"), you ("user," "you," or "your") agree to be bound by these Terms and Conditions ("Terms"). If you do not agree to these Terms, you must not access or use our platform.</p>
  
  <h3 className="text-xl font-semibold mt-6">2. Eligibility</h3>
  <p>You must be at least 16 years old to use our services. By using our platform, you represent and warrant that you meet this eligibility requirement.</p>
  
  <h3 className="text-xl font-semibold mt-6">3. Account Registration</h3>
  <p>To access certain features, you may need to create an account. You agree to provide accurate, current, and complete information during the registration process and to keep this information up-to-date. You are responsible for maintaining the confidentiality of your account credentials.</p>
  
  <h3 className="text-xl font-semibold mt-6">4. Platform Use</h3>
  <p>You agree to use the platform responsibly and in compliance with all applicable laws and regulations. Prohibited activities include, but are not limited to:</p>
  <ul className="list-disc ml-6">
    <li>Engaging in any unlawful or fraudulent activities.</li>
    <li>Distributing malware or other harmful code.</li>
    <li>Infringing upon the intellectual property rights of others.</li>
    <li>Harassing, abusing, or harming other users.</li>
    <li>Circumventing any security measures of the platform.</li>
  </ul>
  <p>We reserve the right to investigate and take appropriate legal action against anyone who violates this provision, including terminating accounts without notice.</p>
  
  <h3 className="text-xl font-semibold mt-6">5. Payments and Subscriptions</h3>
  <p>All payments are processed through <strong>Stripe</strong>. By making a payment, you agree to Stripe’s <a href="https://stripe.com/legal">Terms of Service</a> and <a href="https://stripe.com/privacy">Privacy Policy</a>.</p>
  <p>Subscription payments are non-refundable, except under extreme circumstances at our sole discretion. Refund requests will be handled on a case-by-case basis. Prices and payment terms are subject to change upon prior notice to you.</p>
  
  <h3 className="text-xl font-semibold mt-6">6. Free Trials and Promotions</h3>
  <p>We may offer free trials or promotional offers. These are subject to these Terms and any additional terms provided at the time of the offer. We reserve the right to modify or terminate such offers at any time.</p>
  
  <h3 className="text-xl font-semibold mt-6">7. Intellectual Property Rights</h3>
  <p>All content on the platform, including text, graphics, logos, and software, is the property of <strong>Transacly.co</strong> or its content suppliers and is protected by international copyright laws. You are granted a limited license to access and use the platform for personal, non-commercial purposes.</p>
  
  <h3 className="text-xl font-semibold mt-6">8. Third-Party Services</h3>
  <p>Our platform may include links to third-party websites or services that are not owned or controlled by us. We have no control over, and assume no responsibility for, the content or practices of any third-party sites or services.</p>
  
  <h3 className="text-xl font-semibold mt-6">9. Platform Availability</h3>
  <p>We strive to keep the platform available at all times but do not guarantee uninterrupted access. We may add, remove, or modify features at any time without prior notice. We reserve the right to shut down the platform temporarily or permanently if needed for maintenance or security reasons.</p>
  
  <h3 className="text-xl font-semibold mt-6">10. Data and Security</h3>
  <p>While we follow best practices for security, data breaches are possible. We cannot guarantee the security of data handled by third-party services such as Stripe, Vercel, and Cloudflare. You acknowledge and agree that we are not liable for any unauthorized access to or alteration of your transmissions or data.</p>
  
  <h3 className="text-xl font-semibold mt-6">11. Disclaimer of Warranties</h3>
  <p>The platform is provided on an "as is" and "as available" basis. We make no warranties or representations about the accuracy or completeness of the platform's content or the content of any sites linked to the platform.</p>
  
  <h3 className="text-xl font-semibold mt-6">12. Limitation of Liability</h3>
  <p>To the fullest extent permitted by law, in no event shall <strong>Transacly.co</strong>, its affiliates, or their respective directors, employees, or agents be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or related to your use of the platform.</p>
  
  <h3 className="text-xl font-semibold mt-6">13. Indemnification</h3>
  <p>You agree to indemnify and hold harmless <strong>Transacly.co</strong> and its affiliates from any claims, losses, liabilities, damages, costs, or expenses arising out of your use of the platform or violation of these Terms.</p>
  
  <h3 className="text-xl font-semibold mt-6">14. Account Termination</h3>
  <p>We reserve the right to suspend or terminate your account at our sole discretion if you violate these Terms or engage in illegal or harmful activities. If your account is blocked on Stripe, we cannot intervene.</p>
  
  <h3 className="text-xl font-semibold mt-6">15. Governing Law</h3>
  <p>These Terms shall be governed by and construed in accordance with the laws of the United Kingdom, without regard to its conflict of law provisions.</p>
  
  <h3 className="text-xl font-semibold mt-6">16. Dispute Resolution</h3>
  <p>Any disputes arising out of or relating to these Terms shall be resolved through amicable negotiations. If such negotiations fail, disputes shall be submitted to the exclusive jurisdiction of the courts of England and Wales.</p>
  
  <h3 className="text-xl font-semibold mt-6">17. Changes to Terms</h3>
  <p>We may update these Terms from time to time. Any changes will be effective immediately upon posting on this page with an updated revision date. Your continued use of the platform after any such changes constitutes your acceptance of the new Terms.</p>
  
  <h3 className="text-xl font-semibold mt-6">18. Severability</h3>
  <p>If any provision of these Terms is found to be unenforceable or invalid, that provision will be limited or eliminated to the minimum extent necessary, and the remaining provisions shall remain in full force and effect.</p>
  
  <h3 className="text-xl font-semibold mt-6">19. Entire Agreement</h3>
  <p>These Terms, along with our <a href="/privacy-policy">Privacy Policy</a>, constitute the entire agreement between you and <strong>Transacly.co</strong> regarding the use of the platform.</p>
  
  <h3 className="text-xl font-semibold mt-6">20. Contact Us</h3>
  <p>If you have any questions about these Terms, please contact us at:</p>
  <ul className="list-disc ml-6">
    <li><strong>Email:</strong> <a href="mailto:[your-email@example.com]">support@proxitech.co.uk</a></li>
    <li><strong>Postal Address:</strong> [Your Company Address]</li>
    <li><strong>Company Information:</strong> Registered under the company name <strong>Proxy Node Networks LTD</strong>, Company Number: 14173560.</li>
  </ul>
  </section>
        {/* Data Sharing Policy */}
        <section className="mb-12">
  <h2 className="text-3xl font-semibold mb-4">Data Sharing Policy</h2>
  <p><em>Last Updated: 01/10/2024</em></p>
  
  <h3 className="text-xl font-semibold mt-6">1. Introduction</h3>
  <p>At <strong>Transacly.co</strong> ("we," "us," or "our"), we are committed to protecting your personal data. This Data Sharing Policy outlines how we share your information with third parties in compliance with the General Data Protection Regulation (GDPR) and other applicable laws.</p>
  
  <h3 className="text-xl font-semibold mt-6">2. Data We Share</h3>
  <p>We only share your personal data with third parties who are essential for operating our platform and providing our services. The types of data we may share include:</p>
  <ul className="list-disc ml-6">
    <li><strong>Identity and Contact Data:</strong> Such as your name and email address.</li>
    <li><strong>Financial Data:</strong> Payment information processed through our payment provider.</li>
    <li><strong>Technical Data:</strong> IP addresses and browser details for security and functionality.</li>
  </ul>
  
  <h3 className="text-xl font-semibold mt-6">3. Third-Party Service Providers</h3>
  <p>We engage the following third-party service providers to assist in delivering our services:</p>
  <ul className="list-disc ml-6">
    <li><strong>Stripe:</strong> For payment processing. <a href="https://stripe.com/privacy" className="text-blue-500" target="_blank" rel="noopener noreferrer">View Stripe's Privacy Policy</a>.</li>
    <li><strong>Vercel:</strong> For hosting our platform. <a href="https://vercel.com/legal/privacy-policy" className="text-blue-500" target="_blank" rel="noopener noreferrer">View Vercel's Privacy Policy</a>.</li>
    <li><strong>Cloudflare:</strong> For security and proxy services. <a href="https://www.cloudflare.com/privacypolicy/" className="text-blue-500" target="_blank" rel="noopener noreferrer">View Cloudflare's Privacy Policy</a>.</li>
  </ul>
  <p>These providers process your data only for the purposes specified and in accordance with our instructions.</p>
  
  <h3 className="text-xl font-semibold mt-6">4. Legal Requirements</h3>
  <p>We may disclose your personal data to comply with legal obligations, respond to lawful requests from public authorities, or protect our rights and safety. Such disclosures may include:</p>
  <ul className="list-disc ml-6">
    <li>Complying with court orders or legal processes.</li>
    <li>Enforcing our Terms and Conditions.</li>
    <li>Protecting against legal liability.</li>
  </ul>
  
  <h3 className="text-xl font-semibold mt-6">5. International Data Transfers</h3>
  <p>Your personal data may be transferred to and processed in countries outside of your country of residence, including countries outside the European Economic Area (EEA). We ensure that appropriate safeguards are in place to protect your data, such as standard contractual clauses or equivalent mechanisms.</p>
  
  <h3 className="text-xl font-semibold mt-6">6. Data Security</h3>
  <p>We implement appropriate technical and organizational measures to safeguard your personal data. While we strive to protect your information, we cannot guarantee absolute security. Our third-party service providers also employ security measures to protect your data.</p>
  
  <h3 className="text-xl font-semibold mt-6">7. Changes to This Policy</h3>
  <p>We may update this Data Sharing Policy from time to time. Any changes will be posted on this page with an updated revision date. We encourage you to review this policy periodically to stay informed about how we share your data.</p>
  
  <h3 className="text-xl font-semibold mt-6">8. Contact Us</h3>
  <p>If you have any questions regarding our Data Sharing Policy, please contact us at:</p>
  <ul className="list-disc ml-6">
    <li><strong>Email:</strong> <a href="mailto:[your-email@example.com]">support@proxitech.co.uk</a></li>
    <li><strong>Postal Address:</strong> Hexagon House, Avenue Four, Witney, England, OX28 4BN</li>
  </ul>
  </section>
      </div>
    </div>
  );
};

export default Policies;