import React from 'react';
import { useNavigate } from 'react-router-dom';

const ErrorPage = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate('/'); // Redirect to the home page or any other page
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-6 rounded shadow-md text-center">
        <div className="text-6xl text-red-500 mb-4">❌</div>
        <h3 className="text-xl mb-4">Something is not right...</h3>
        <p className="text-gray-700 mb-4">We have a problem, please try again late. Click the button to go home.</p>
        <button 
          onClick={handleGoBack} 
          className="px-4 py-2 bg-blue-500 text-white rounded shadow hover:bg-blue-600"
        >
          Go Back
        </button>
      </div>
    </div>
  );
};

export default ErrorPage;