import React from 'react';
import { FaInfoCircle } from 'react-icons/fa';

const Tooltip = ({ text }) => {
    return (
        <div className="ml-2 relative group">
            {/* Info icon */}
            <FaInfoCircle className="text-blue-500 cursor-pointer" />
            
            {/* Tooltip box */}
            <div className="absolute bottom-0 left-0 transform translate-y-12 opacity-0 group-hover:opacity-100 transition-opacity bg-white text-black text-sm rounded-lg shadow-lg py-2 px-4 z-50 w-48">
                <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-2 w-4 h-4 bg-white rotate-45 z-0"></div>
                <div className="relative z-10">{text}</div>
            </div>
        </div>
    );
};

export default Tooltip;