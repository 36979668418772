import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

const RedirectToCheckout = () => {
  const { uniqueId } = useParams(); // Get the unique ID from the URL
  const navigate = useNavigate();
  const [challengeSolved, setChallengeSolved] = useState(false); // Track if the challenge is solved
  const [checkoutUrl, setCheckoutUrl] = useState(''); // Checkout URL from backend
  const [userAnswer, setUserAnswer] = useState(''); // Store user answer for challenge
  const [challenge, setChallenge] = useState({ question: '', answer: '' }); // Challenge question and correct answer

  useEffect(() => {
    // Fetch checkout link from backend
    const fetchCheckoutLink = async () => {
      try {
        const response = await axios.get(`https://api.transacly.co/link/${uniqueId}`);
        console.log('Backend response:', response.data);  // Log the response to inspect its structure

        // Ensure that the response contains the correct data
        if (response.data && response.data.checkout_url) {
          setCheckoutUrl(response.data.checkout_url);  // Extract and set the checkout URL
          generateChallenge(); // Generate a captcha-like challenge
        } else {
          console.error('No checkout URL found in the response.');
          navigate('/404'); // Redirect to a 404 page if no checkout URL is found
        }
      } catch (error) {
        console.error('Error fetching checkout link:', error);
        navigate('/404'); // Redirect to a 404 page if an error occurs
      }
    };

    fetchCheckoutLink();
  }, [uniqueId, navigate]);

  // Generate a simple math challenge
  const generateChallenge = () => {
    const num1 = Math.floor(Math.random() * 10) + 1;
    const num2 = Math.floor(Math.random() * 10) + 1;
    setChallenge({ question: `What is ${num1} + ${num2}?`, answer: (num1 + num2).toString() });
  };

  // Handle challenge answer submission
  const handleAnswerSubmit = (e) => {
    e.preventDefault();
    if (userAnswer === challenge.answer) {
      setChallengeSolved(true); // Set challenge solved to true
      window.location.href = checkoutUrl; // Redirect to Stripe checkout
    } else {
      alert('Incorrect answer. Please try again.');
      setUserAnswer(''); // Clear input
      generateChallenge(); // Generate new challenge
    }
  };

  return (
    <div className="min-h-screen flex flex-col justify-center items-center">
      {challengeSolved ? (
        <p>Redirecting to checkout...</p> // Shown while redirect happens
      ) : (
        <div className="bg-white p-6 rounded shadow-md max-w-lg w-full text-center">
          <h3 className="text-xl font-semibold mb-4">Prove you're not a robot!</h3>
          <p className="mb-4">{challenge.question}</p>
          <form onSubmit={handleAnswerSubmit} className="flex justify-center">
            <input
              type="text"
              value={userAnswer}
              onChange={(e) => setUserAnswer(e.target.value)}
              className="border p-2 rounded mr-2"
              placeholder="Enter your answer"
              required
            />
            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded transition-transform transform hover:scale-105"
            >
              Submit
            </button>
          </form>
          <h3 className='font-light text-gray-500 pt-2'>Payment Link Generated by <a href='https://transacly.co/'>Transacly.co</a></h3>
        </div>
      )}
    </div>
  );
};

export default RedirectToCheckout;