import React, { useState } from 'react';
import { FaCheck, FaCopy } from 'react-icons/fa';

const Modal = ({ link, onClose }) => {
  const [isCopied, setIsCopied] = useState(false); // Track whether the link has been copied

  const copyToClipboard = () => {
    navigator.clipboard.writeText(link)
      .then(() => {
        // Show feedback: set button to "Copied"
        setIsCopied(true);
        
        // Reset the button back after 2 seconds
        setTimeout(() => {
          setIsCopied(false);
        }, 2000);
      })
      .catch((error) => {
        console.error('Failed to copy the link:', error);
      });
  };

  return (
    <div className="fixed z-50 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen px-4">
        <div className="bg-white p-6 rounded shadow-md max-w-lg w-full">
          <h3 className="text-xl font-semibold mb-4">Purchase Link</h3>
          <p className="mb-4">
            Use the link below to allow users to purchase the product:
          </p>
          <div className="bg-gray-100 p-4 rounded mb-4">
            <p className="break-all">{link}</p>
          </div>
          <div className="flex justify-end">
            <button
              onClick={copyToClipboard}
              className={`px-4 py-2 rounded mr-2 transition-transform transform hover:scale-105 flex items-center ${
                isCopied ? 'bg-green-500 text-white' : 'bg-blue-500 text-white'
              }`}
            >
              {isCopied ? (
                <>
                  <FaCheck className="mr-2" /> Copied
                </>
              ) : (
                <>
                  <FaCopy className="mr-2" /> Copy to Clipboard
                </>
              )}
            </button>
            <button
              onClick={onClose}
              className="bg-gray-500 text-white px-4 py-2 rounded transition-transform transform hover:scale-105"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;