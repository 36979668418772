import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import '@fortawesome/fontawesome-free/css/all.min.css';

const HomePage = () => {
  const [faqOpen, setFaqOpen] = useState(null);
  const [metaTitle, setMetaTitle] = useState('Transacly - Effortless Invoice Management');
  const [metaDescription, setMetaDescription] = useState('Transacly offers effortless invoice management solutions with easy-to-use tools to generate, send, and track invoices.');

  const toggleFaq = (index) => {
    setFaqOpen(faqOpen === index ? null : index);
  };

  useEffect(() => {
    // Example of AI-driven dynamic meta optimization
    const optimizedTitle = 'Efficient Invoice Management with Transacly - Optimize Payments';
    const optimizedDescription = 'Boost productivity with Transacly, the optimized invoicing tool for seamless management and tracking of transactions.';

    setMetaTitle(optimizedTitle);
    setMetaDescription(optimizedDescription);

    // Dynamic CTA adjustment
    const ctaText = "Sign Up Today!";
    setCtaText(ctaText);
  }, []);

  // Dynamic CTA text state
  const [ctaText, setCtaText] = useState('Get Started');

  // State variables for the calculator
  const [monthlySales, setMonthlySales] = useState(10000); // default $10,000 per month
  const [transactionsPerMonth, setTransactionsPerMonth] = useState(100); // default 100 transactions per month
  const [includeInternationalFees, setIncludeInternationalFees] = useState(false);
  const [fees, setFees] = useState({
    averageTransactionAmount: 0,
    processingFeePerTransaction: 0,
    invoicingFeePerTransaction: 0,
    internationalFeePerTransaction: 0,
    totalProcessingFeesPerMonth: 0,
    totalInvoicingFeesPerMonth: 0,
    totalInternationalFeesPerMonth: 0,
    totalStripeFeesPerMonth: 0,
    totalFeesWithTransaclyPerMonth: 0,
    amountSavedPerMonth: 0,
    totalStripeFeesPerYear: 0,
    totalFeesWithTransaclyPerYear: 0,
    amountSavedPerYear: 0,
    percentageSaved: 0,
  });

  const calculateFees = (monthlySales, transactionsPerMonth) => {
    // Ensure the inputs are valid
    if (
      isNaN(monthlySales) ||
      monthlySales <= 0 ||
      isNaN(transactionsPerMonth) ||
      transactionsPerMonth <= 0
    ) {
      // Set fees to zero
      setFees({
        averageTransactionAmount: 0,
        processingFeePerTransaction: 0,
        invoicingFeePerTransaction: 0,
        internationalFeePerTransaction: 0,
        totalProcessingFeesPerMonth: 0,
        totalInvoicingFeesPerMonth: 0,
        totalInternationalFeesPerMonth: 0,
        totalStripeFeesPerMonth: 0,
        totalFeesWithTransaclyPerMonth: 0,
        amountSavedPerMonth: 0,
        totalStripeFeesPerYear: 0,
        totalFeesWithTransaclyPerYear: 0,
        amountSavedPerYear: 0,
        percentageSaved: 0,
      });
      return;
    }

    // Calculate average transaction amount
    const averageTransactionAmount = monthlySales / transactionsPerMonth;

    // Stripe processing fee per transaction: 2.9% + $0.30
    const processingFeePerTransaction = averageTransactionAmount * 0.029 + 0.30;

    // Stripe invoicing fee per transaction: 0.4% per paid invoice (max $2)
    let invoicingFeePerTransaction = averageTransactionAmount * 0.004;
    if (invoicingFeePerTransaction > 2) {
      invoicingFeePerTransaction = 2;
    }

    // Optional: International card fee per transaction: 1%
    let internationalFeePerTransaction = 0;
    if (includeInternationalFees) {
      internationalFeePerTransaction = averageTransactionAmount * 0.01;
    }

    // Total fees per transaction
    const totalFeePerTransaction =
      processingFeePerTransaction + invoicingFeePerTransaction + internationalFeePerTransaction;

    // Monthly fees
    const totalProcessingFeesPerMonth = processingFeePerTransaction * transactionsPerMonth;
    const totalInvoicingFeesPerMonth = invoicingFeePerTransaction * transactionsPerMonth;
    const totalInternationalFeesPerMonth =
      internationalFeePerTransaction * transactionsPerMonth;
    const totalStripeFeesPerMonth =
      totalProcessingFeesPerMonth + totalInvoicingFeesPerMonth + totalInternationalFeesPerMonth;

    // Fees with Transacly (eliminates invoicing fees)
    const totalFeesWithTransaclyPerMonth =
      totalProcessingFeesPerMonth + totalInternationalFeesPerMonth;
    const amountSavedPerMonth = totalStripeFeesPerMonth - totalFeesWithTransaclyPerMonth;
    const percentageSaved = ((amountSavedPerMonth / totalStripeFeesPerMonth) * 100).toFixed(2);

    // Annual fees
    const totalStripeFeesPerYear = totalStripeFeesPerMonth * 12;
    const totalFeesWithTransaclyPerYear = totalFeesWithTransaclyPerMonth * 12;
    const amountSavedPerYear = amountSavedPerMonth * 12;

    setFees({
      averageTransactionAmount,
      processingFeePerTransaction,
      invoicingFeePerTransaction,
      internationalFeePerTransaction,
      totalProcessingFeesPerMonth,
      totalInvoicingFeesPerMonth,
      totalInternationalFeesPerMonth,
      totalStripeFeesPerMonth,
      totalFeesWithTransaclyPerMonth,
      amountSavedPerMonth,
      totalStripeFeesPerYear,
      totalFeesWithTransaclyPerYear,
      amountSavedPerYear,
      percentageSaved,
    });
  };

  // Recalculate fees whenever the inputs change
  useEffect(() => {
    calculateFees(monthlySales, transactionsPerMonth);
  }, [monthlySales, transactionsPerMonth, includeInternationalFees]);

  // Data for the bar chart
  const chartData = {
    labels: ['Total Fees per Year'],
    datasets: [
      {
        label: 'Using Stripe',
        data: [fees.totalStripeFeesPerYear],
        backgroundColor: '#6366F1', // Indigo
      },
      {
        label: 'Using Transacly',
        data: [fees.totalFeesWithTransaclyPerYear],
        backgroundColor: '#10B981', // Green
      },
    ],
  };

  const chartOptions = {
    scales: {
      x: { stacked: false },
      y: { beginAtZero: true },
    },
  };

  return (
    <div className="min-h-screen bg-white">
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta name="keywords" content="Invoicing, payment management, stripe integration, automation, online payments, Transacly" />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Transacly" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        {/* Open Graph for Social Media */}
        <meta property="og:title" content="Efficient Invoice Management with Transacly - Optimize Payments" />
    <meta property="og:description" content="Boost productivity with Transacly, the optimized invoicing tool for seamless management and tracking of transactions." />
    <meta property="og:image" content="https://transacly.co/x-image-card.png" />
    <meta property="og:url" content="https://transacly.co/" />
    <meta property="og:type" content="website" />

        {/* Twitter Cards */}
        <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content='Transacly - Effortless Invoice Management' />
    <meta name="twitter:description" content='Transacly offers effortless invoice management solutions with easy-to-use tools to generate, send, and track invoices'/>
    <meta name="twitter:image" content="https://transacly.co/x-image-card.png" />
    <meta name="twitter:site" content="@Transacly" />
      </Helmet>
      <style jsx>{`
        .banner {
          background-color: #fbbf24; /* Yellow color */
          color: #000;
          padding: 10px;
          font-size: 18px;
          font-weight: bold;
          display: flex;
          align-items: center;
          overflow: hidden;
          white-space: nowrap;
        }
        .scroll-container {
          display: flex;
          min-width: 100%;
          animation: scroll 20s linear infinite;
        }
        @keyframes scroll {
          0% { transform: translateX(0); }
          100% { transform: translateX(-100%); }
        }
      `}</style>

      {/* Advertisement Banner */}
      <div className="banner">
        <div className="scroll-container">
          <div className="scroll-text">
            🎉 Launch Deal: 50% off annual plan! Get it now for only £27.49/year (from £54.98) 🎉
            🎉 Launch Deal: 66.78% off monthly plan for your first month! Get it now for only £1.99/month (from £5.99) 🎉
          </div>
          <div className="scroll-text">
            🎉 Launch Deal: 50% off annual plan! Get it now for only £27.49/year (from £54.98) 🎉
            🎉 Launch Deal: 66.78% off monthly plan for your first month! Get it now for only £1.99/month (from £5.99) 🎉
          </div>
        </div>
      </div>
      <style jsx>{`
        .gradient-text {
          background: linear-gradient(90deg, #6b46c1, #805ad5);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        .features-list li::before {
          content: '\\2022';
          color: #6b46c1;
          font-weight: bold;
          display: inline-block;
          width: 1em;
          margin-left: -1em;
        }
        .faq-question::after {
          content: '\\25BC';
          float: right;
          transition: transform 0.3s;
        }
        .faq-question.open::after {
          transform: rotate(180deg);
        }
        .section-gradient {
          background: linear-gradient(180deg, var(--tw-gradient-stops));
        }
        .bg-fade-white-to-gray {
          --tw-gradient-stops: white, #f3f4f6;
        }
        .bg-fade-gray-to-white {
          --tw-gradient-stops: #f3f4f6, white;
        }
        .bg-fade-white-to-indigo {
          --tw-gradient-stops: white, #ebf4ff;
        }
        .bg-fade-indigo-to-white {
          --tw-gradient-stops: #ebf4ff, white;
        }
      `}</style>

      {/* Top Nav/Header Section */}
      <header className="top-0 ease-in-out bg-white shadow-md">
        <div className="mx-auto py-6 px-4 sm:px-6 lg:px-8 flex justify-between items-center w-full">
          <div className="flex items-center">
            <h1 className="text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-purple-500 to-indigo-600 transition-colors duration-300">Transacly.</h1>
          </div>
          <nav>
            <a href="/login" className="ml-4 inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-indigo-50">Login</a>
            <a href="/signup" className="ml-4 inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-indigo-50">Sign Up</a>
          </nav>
        </div>
      </header>

      <main>
      <div
  id="hero"
  className="relative min-h-screen flex items-center justify-center bg-white section-gradient bg-fade-white-to-indigo"
>
  <div
    className="absolute inset-0 bg-cover bg-center"
    style={{ backgroundImage: "url('/hero-background.jpg')" }}
  ></div>
  <div className="relative z-10 w-full max-w-7xl px-4 sm:px-6 lg:px-8">
    <div className="flex flex-col-reverse md:flex-row items-center justify-between">
      {/* Right Side: Transacly Heading and Subheading */}
      <div className="w-full md:w-1/2 p-4 flex flex-col items-center md:items-start">
        <h2 className="text-5xl font-extrabold gradient-text mt-8 md:mt-0 text-center md:text-left">
          One Platform for Invoicing, Customers, and Subscriptions.
        </h2>
        <p className="mt-4 text-2xl text-gray-700 text-center md:text-left">
          More than just invoicing – manage customers, products, and subscriptions with ease.
        </p>
        <p className="mt-4 text-lg text-gray-700 text-center md:text-left">
          Powered by Stripe, Transacly allows you to sync your products, customers, and payment data. Create easy payment links, set up subscriptions, or process one-time payments seamlessly.
        </p>
        <div className="mt-8">
          <a
            href="/signup"
            className="inline-flex items-center justify-center px-8 py-4 border border-transparent text-lg font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-700"
          >
            {ctaText}
          </a>
        </div>
      </div>
      {/* Left Side: Calculator */}
      <div className="w-full md:w-1/2 p-4">
        {/* Calculator Section */}
        <div className="bg-white bg-opacity-90 backdrop-filter backdrop-blur-md shadow-md rounded-lg p-6">
          <h3 className="text-2xl font-bold mb-4 text-gray-900 text-center">
            See How Much You Can Save Annually
          </h3>
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="monthlySales"
                  >
                    Enter Your Monthly Sales Amount:
                  </label>
                  <input
                    id="monthlySales"
                    type="number"
                    value={monthlySales}
                    onChange={(e) => setMonthlySales(parseFloat(e.target.value) || 0)}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Enter monthly sales in USD"
                  />
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2 mt-4"
                    htmlFor="transactionsPerMonth"
                  >
                    Enter Number of Transactions per Month:
                  </label>
                  <input
                    id="transactionsPerMonth"
                    type="number"
                    value={transactionsPerMonth}
                    onChange={(e) => setTransactionsPerMonth(parseInt(e.target.value) || 0)}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="e.g., 100"
                  />
                  <div className="mt-4">
                    <label className="inline-flex items-center">
                      <input
                        type="checkbox"
                        className="form-checkbox h-5 w-5 text-indigo-600"
                        checked={includeInternationalFees}
                        onChange={(e) => setIncludeInternationalFees(e.target.checked)}
                      />
                      <span className="ml-2 text-gray-700">
                        Include International Card Fees (1%)
                      </span>
                    </label>
                  </div>
                  {/* Breakdown Window */}
                  <div className="mt-6">
                    <div className="bg-gray-50 shadow-inner rounded px-6 py-4">
                      <h4 className="text-xl font-semibold mb-4">Annual Fees Breakdown</h4>
                      <p className="text-gray-700">
                        Average Transaction Amount:{' '}
                        <span className="font-medium">
                          ${fees.averageTransactionAmount.toFixed(2)}
                        </span>
                      </p>
                      <p className="text-gray-700">
                        Stripe Processing Fee per Transaction (2.9% + $0.30):{' '}
                        <span className="font-medium">
                          ${fees.processingFeePerTransaction.toFixed(2)}
                        </span>
                      </p>
                      <p className="text-gray-700">
                        Stripe Invoicing Fee per Transaction (0.4%):{' '}
                        <span className="font-medium">
                          ${fees.invoicingFeePerTransaction.toFixed(2)}
                        </span>
                      </p>
                      {includeInternationalFees && (
                        <p className="text-gray-700">
                          International Card Fee per Transaction (1%):{' '}
                          <span className="font-medium">
                            ${fees.internationalFeePerTransaction.toFixed(2)}
                          </span>
                        </p>
                      )}
                      <hr className="my-2" />
                      <p className="text-gray-900 font-bold">
                        Total Stripe Fees per Year: ${fees.totalStripeFeesPerYear.toFixed(2)}
                      </p>
                      <p className="text-gray-900 font-bold">
                        Total Fees with Transacly per Year: $
                        {fees.totalFeesWithTransaclyPerYear.toFixed(2)}
                      </p>
                      <p className="text-green-600 font-bold mt-2">
                        Annual Savings with Transacly: ${fees.amountSavedPerYear.toFixed(2)} (
                        {fees.percentageSaved}%)
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Invoicing Section */}
        <div className="py-12 bg-white section-gradient bg-fade-indigo-to-white">
  <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 lg:text-center">
    <h2 className="text-3xl font-extrabold tracking-tight bg-clip-text text-transparent bg-gradient-to-r from-purple-500 to-indigo-600 sm:text-4xl">
      Ready to Manage Invoices, Customers, and Subscriptions Seamlessly?
    </h2>
    <p className="mt-4 text-lg leading-6 text-gray-700">
      Join Transacly today and take control of your invoicing, customer data, product catalog, and subscription management — all in one place.
    </p>
    <p className="mt-4 text-lg leading-6 text-gray-700">
      Powered by Stripe, Transacly simplifies every aspect of your billing process, whether it’s generating invoices, setting up payment links, or managing recurring billing.
    </p>
    <div className="mt-8 flex justify-center">
      <div className="inline-flex rounded-md shadow">
        <a href="/signup" className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-indigo-50">
          Get Started with Transacly
        </a>
      </div>
    </div>
  </div>
</div>
{/* Transactions & Invoice Management Section */}
<div id="transactions" className="py-16 section-gradient bg-fade-white-to-gray">
  <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 lg:flex lg:items-center">
    <div className="lg:w-1/2 pr-8">
      <h2 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl">
        Supercharge Your Stripe Account with Transacly
      </h2>
      <p className="mt-4 text-xl text-gray-500">
        Transacly gives you complete control over your Stripe account. Seamlessly manage all your transactions, customers, products, and subscriptions in one place—while cutting down on unnecessary fees.
      </p>
      <ul className="mt-6 space-y-4 text-lg text-gray-700">
        <li className="flex items-start">
          <i className="fas fa-money-bill-wave text-indigo-600 mr-3"></i> 
          Save on Stripe's invoicing fees by automating your payment processes.
        </li>
        <li className="flex items-start">
          <i className="fas fa-users text-indigo-600 mr-3"></i> 
          Effortlessly sync and manage your customers, products, and subscriptions in real-time.
        </li>
        <li className="flex items-start">
          <i className="fas fa-chart-bar text-indigo-600 mr-3"></i> 
          Gain instant access to real-time transaction data and reporting, keeping you in full control of your finances.
        </li>
        <li className="flex items-start">
          <i className="fas fa-paper-plane text-indigo-600 mr-3"></i> 
          Create, send, and track invoices with just a few clicks. No more manual data entry.
        </li>
      </ul>
    </div>
    <div className="lg:w-1/2 mt-8 lg:mt-0">
      <div className="relative">
        <img src="/image1.png" alt="Transacly Transactions Dashboard" className="relative rounded-lg shadow-lg opacity-80" loading="lazy" />
      </div>
    </div>
  </div>
</div>

{/* View & Manage Your Transactions Section */}
<div id="invoices" className="py-16 bg-gray-100 section-gradient bg-fade-gray-to-white">
  <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 lg:flex lg:items-center">
    <div className="lg:w-1/2 lg:order-2 pl-8">
      <h2 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl">
        Stay on Top of Your Financial Activity
      </h2>
      <p className="mt-4 text-xl text-gray-500">
        With Transacly, you can view all your Stripe transactions in one place, effortlessly manage your cash flow, and take full control of your financial data—helping you save both time and money.
      </p>
      <ul className="mt-6 space-y-4 text-lg text-gray-700">
        <li className="flex items-start">
          <i className="fas fa-eye text-indigo-600 mr-3"></i> 
          Instantly view all your Stripe transactions, customer data, and subscription payments in one easy-to-read dashboard.
        </li>
        <li className="flex items-start">
          <i className="fas fa-sync-alt text-indigo-600 mr-3"></i> 
          Sync your products, customers, and payments automatically—so you never miss an update.
        </li>
        <li className="flex items-start">
          <i className="fas fa-file-download text-indigo-600 mr-3"></i> 
          Download detailed financial reports and transaction statements, perfect for bookkeeping and tracking trends.
        </li>
        <li className="flex items-start">
          <i className="fas fa-coins text-indigo-600 mr-3"></i> 
          Save money by reducing Stripe fees and automating recurring payments, all without additional hassle.
        </li>
      </ul>
    </div>
    <div className="lg:w-1/2 mt-8 lg:mt-0 lg:order-1">
      <div className="relative">
        <img src="image3.png" alt="Financial Dashboard" className="relative rounded-lg shadow-lg opacity-80" loading="lazy" />
      </div>
    </div>
  </div>
</div>

        {/* Stats Section */}
        <div className="py-12 section-gradient bg-fade-white-to-gray">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="lg:text-center">
              <h2 className="text-base text-indigo-600 font-semibold tracking-wide uppercase">Saving Time & Money</h2>
              <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Save Money with Transacly
              </p>
              <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
                Start saving in three simple steps. No more Stripe Invoice Fees.
              </p>
            </div>
            <div className="mt-10">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                <div className="p-6 bg-white rounded-lg shadow-md">
                  <h3 className="text-lg font-medium text-gray-900">1. Create an account</h3>
                  <p className="mt-2 text-base text-gray-500">Sign up and set up your account in minutes.</p>
                </div>
                <div className="p-6 bg-white rounded-lg shadow-md">
                  <h3 className="text-lg font-medium text-gray-900">2. Enter you Restricted Stripe API Key</h3>
                  <p className="mt-2 text-base text-gray-500">Securely integrates your Stripe account. With us.</p>
                </div>
                <div className="p-6 bg-white rounded-lg shadow-md">
                  <h3 className="text-lg font-medium text-gray-900">3. Enter your company info</h3>
                  <p className="mt-2 text-base text-gray-500">Provide your business information for billing purposes.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Features Section */}
        <div id="features" className="py-12 bg-white section-gradient bg-fade-gray-to-white">
  <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
    <div className="lg:text-center">
      <h2 className="text-base text-indigo-600 font-semibold tracking-wide uppercase">Features</h2>
      <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
        All the Tools You Need to Run Your Business
      </p>
      <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
        Transacly empowers your business with seamless integrations, simplified invoicing, and complete control over payments, subscriptions, and customer management.
      </p>
    </div>

    {/* Features Grid */}
    <div className="mt-10 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
      {/* Feature 1: Invoice Generation */}
      <div className="p-6 bg-white rounded-lg shadow-md hover:shadow-xl transform hover:scale-105 transition duration-300 ease-in-out">
        <div className="flex justify-center text-indigo-600">
          <i className="fas fa-file-invoice-dollar fa-3x"></i>
        </div>
        <h3 className="mt-4 text-xl font-bold text-indigo-600 text-center">Generate Invoices in Seconds</h3>
        <p className="mt-2 text-base text-gray-500 text-center">
          Create professional invoices with just a few clicks, fully integrated with your products and customer data.
        </p>
      </div>

      {/* Feature 2: Send Invoices */}
      <div className="p-6 bg-white rounded-lg shadow-md hover:shadow-xl transform hover:scale-105 transition duration-300 ease-in-out">
        <div className="flex justify-center text-indigo-600">
          <i className="fas fa-envelope fa-3x"></i>
        </div>
        <h3 className="mt-4 text-xl font-bold text-indigo-600 text-center">Send Invoices Effortlessly</h3>
        <p className="mt-2 text-base text-gray-500 text-center">
          Email invoices directly to your customers with a single click, saving time and ensuring faster payments.
        </p>
      </div>

      {/* Feature 3: Product Management */}
      <div className="p-6 bg-white rounded-lg shadow-md hover:shadow-xl transform hover:scale-105 transition duration-300 ease-in-out">
        <div className="flex justify-center text-indigo-600">
          <i className="fas fa-box-open fa-3x"></i>
        </div>
        <h3 className="mt-4 text-xl font-bold text-indigo-600 text-center">Sync and Manage Products</h3>
        <p className="mt-2 text-base text-gray-500 text-center">
          Easily create, manage, and sync your Stripe products. Set pricing, manage inventory, and generate payment links with ease.
        </p>
      </div>

      {/* Feature 4: Subscription Billing */}
      <div className="p-6 bg-white rounded-lg shadow-md hover:shadow-xl transform hover:scale-105 transition duration-300 ease-in-out">
        <div className="flex justify-center text-indigo-600">
          <i className="fas fa-sync-alt fa-3x"></i>
        </div>
        <h3 className="mt-4 text-xl font-bold text-indigo-600 text-center">Automate Subscription Billing</h3>
        <p className="mt-2 text-base text-gray-500 text-center">
          Set up and manage monthly or yearly billing for your services with fully automated subscription management.
        </p>
      </div>

      {/* Feature 5: Real-Time Transactions */}
      <div className="p-6 bg-white rounded-lg shadow-md hover:shadow-xl transform hover:scale-105 transition duration-300 ease-in-out">
        <div className="flex justify-center text-indigo-600">
          <i className="fas fa-chart-line fa-3x"></i>
        </div>
        <h3 className="mt-4 text-xl font-bold text-indigo-600 text-center">Track Real-Time Transactions</h3>
        <p className="mt-2 text-base text-gray-500 text-center">
          Access and view all your Stripe transactions in one place, providing you with real-time financial insights.
        </p>
      </div>

      {/* Feature 6: Download Statements */}
      <div className="p-6 bg-white rounded-lg shadow-md hover:shadow-xl transform hover:scale-105 transition duration-300 ease-in-out">
        <div className="flex justify-center text-indigo-600">
          <i className="fas fa-file-download fa-3x"></i>
        </div>
        <h3 className="mt-4 text-xl font-bold text-indigo-600 text-center">Download Financial Statements</h3>
        <p className="mt-2 text-base text-gray-500 text-center">
          Easily download detailed transaction statements for your Stripe payments, perfect for accounting and reporting.
        </p>
      </div>
    </div>
  </div>
</div>

        {/* FAQ Section */}
        <div id="faq" className="py-12 bg-gray-100 section-gradient bg-fade-white-to-gray">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="lg:text-center">
              <h2 className="text-base text-indigo-600 font-semibold tracking-wide uppercase">Frequently Asked Questions</h2>
              <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Have Questions? We’ve Got Answers
              </p>
              <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
                Here are some common questions about our platform.
              </p>
            </div>
            <div className="mt-10">
              <div className="space-y-4">
                {[
                  { question: "What is Transacly?", answer: "Transacly is a platform designed to streamline your invoicing process. You can generate, send, and manage invoices with ease." },
                  { question: "How do I create an account?", answer: "Simply click on the 'Sign Up' button on the homepage and fill in your details to create an account." },
                  { question: "How do I integrate my Stripe account?", answer: "You add your stripe Restricted API key during signup (step 2), this is the only time you will be required to enter it." },
                  { question: "Can I download transaction statements?", answer: "Yes, you can download transaction statements based on monthly, weekly, or yearly transactions." },
                  { question: "What are the pricing plans?", answer: "We offer flexible pricing plans: Monthly at £5.99/month, Yearly at £54.98/year, and an Enterprise plan with bespoke features. Please refer to the 'Plans' section for more details." },
                  { question: "Is my data secure?", answer: "Yes, we prioritize data security. We use Stripe API to handle payments and products, ensuring that your data remains secure and accessible even if you choose to leave our platform." },
                ].map((faq, index) => (
                  <div key={index} className="border-b border-gray-200 pb-4">
                    <button
                      onClick={() => toggleFaq(index)}
                      className={`faq-question w-full text-left text-lg font-medium text-gray-900 focus:outline-none ${faqOpen === index ? 'open' : ''}`}
                    >
                      {faq.question}
                    </button>
                    {faqOpen === index && (
                      <div className="mt-2 text-base text-gray-500">
                        {faq.answer}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

{/* Plans Section */}
<div id="plans" className="py-16 bg-gray-50 section-gradient bg-fade-gray-to-white">
  <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
    <div className="lg:text-center">
      <h2 className="text-base text-indigo-600 font-semibold tracking-wide uppercase">Pricing Plans</h2>
      <p className="mt-2 text-4xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-5xl">
        Choose the Plan that Works for You
      </p>
      <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
        Get the best value for your business with flexible pricing options. No hidden fees. Only transparent pricing.
      </p>
    </div>

    {/* Pricing Plans */}
    <div className="mt-12 grid grid-cols-1 md:grid-cols-3 gap-8">
      {/* Monthly Plan */}
      <div className="relative p-8 bg-white rounded-lg shadow-md hover:shadow-xl border border-indigo-600 hover:scale-105 transition-transform duration-300 ease-in-out">
      {/* Discount Badge */}
      <div className="absolute top-0 right-0 m-4 bg-red-500 text-white text-xs font-bold py-1 px-2 rounded-full">
        66.78% OFF
      </div>
      <div className="text-center">
        <i className="fas fa-calendar-alt fa-3x text-indigo-600"></i>
      </div>
      <h3 className="mt-4 text-lg font-medium text-indigo-600 text-center">Monthly Plan</h3>
      <p className="mt-2 text-base text-gray-500 text-center">
        Full access to our core features, billed monthly with no long-term commitment.
      </p>
      <p className="mt-1 text-xl font-medium text-indigo-600 text-center line-through">£5.99/month</p>
      <p className="text-sm text-gray-500 font-extralight text-center">*This discount for 1 month only.*</p>
      <p className="mt-4 text-3xl font-extrabold text-indigo-600 text-center">£1.99/month</p>
      <ul className="mt-4 text-base text-gray-500">
      <li className="flex items-center"><i className="fas fa-check-circle text-indigo-600 mr-2"></i> Generate unlimited invoices with ease</li>
<li className="flex items-center"><i className="fas fa-check-circle text-indigo-600 mr-2"></i> Send unlimited invoices & reminders</li>
<li className="flex items-center"><i className="fas fa-check-circle text-indigo-600 mr-2"></i> Unlimited product subscriptions</li>
<li className="flex items-center"><i className="fas fa-check-circle text-indigo-600 mr-2"></i> Generate payment links</li>
<li className="flex items-center"><i className="fas fa-check-circle text-indigo-600 mr-2"></i> Real time Sales Overview</li>
      </ul>
      <div className="mt-6 text-center">
        <a href="/signup" className="px-6 py-3 bg-indigo-600 text-white font-medium rounded-md hover:bg-indigo-700">
          Get Started
        </a>
      </div>
    </div>

      {/* Yearly Plan - Highlighted */}
      <div className="relative p-8 bg-indigo-600 text-white rounded-lg shadow-lg hover:shadow-2xl border hover:scale-105 transition-transform duration-300 ease-in-out">
        <div className="absolute top-0 right-0 m-4 bg-red-500 text-white text-xs font-bold py-1 px-2 rounded-full">
          50% OFF
        </div>
        <div className="text-center">
          <i className="fas fa-calendar-check fa-3x"></i>
        </div>
        <h3 className="mt-4 text-lg font-medium text-center">Yearly Plan</h3>
        <p className="mt-2 text-base text-center">
          Pay for the year and get the best value. Plus, access to Beta features before anyone else.
        </p>
        <p className="mt-4 text-xl line-through text-gray-300 text-center">£54.98/year</p>
        <p className="mt-1 text-4xl font-extrabold text-white text-center">£27.49/year</p>
        <ul className="mt-4 text-base text-center">
        <li className="flex items-center"><i className="fas fa-check-circle text-white mr-2"></i> Send unlimited invoices & reminders</li>
        <li className="flex items-center"><i className="fas fa-check-circle text-white mr-2"></i> Unlimited product subscriptions</li>
        <li className="flex items-center"><i className="fas fa-check-circle text-white mr-2"></i> Generate payment links</li>
        <li className="flex items-center"><i className="fas fa-check-circle text-white mr-2"></i> Real time Sales Overview</li>
        </ul>
        <div className="mt-6 text-center">
          <a href="/signup" className="px-6 py-3 bg-white text-indigo-600 font-medium rounded-md hover:bg-indigo-50">
            Get Yearly Plan
          </a>
        </div>
      </div>

      {/* Enterprise Plan */}
      <div className="p-8 bg-white rounded-lg shadow-md hover:shadow-xl border border-indigo-600 hover:scale-105 transition-transform duration-300 ease-in-out">
        <div className="text-center">
          <i className="fas fa-building fa-3x text-indigo-600"></i>
        </div>
        <h3 className="mt-4 text-lg font-medium text-indigo-600 text-center">Enterprise Plan</h3>
        <p className="mt-2 text-base text-gray-500 text-center">
          For larger businesses that need a bespoke platform to manage Stripe invoicing and payments.
        </p>
        <p className="mt-4 text-2xl font-extrabold text-indigo-600 text-center">Enquire for Pricing</p>
        <ul className="mt-4 text-base text-gray-500">
          <li className="flex items-center"><i className="fas fa-check-circle text-indigo-600 mr-2"></i> Custom Setup</li>
          <li className="flex items-center"><i className="fas fa-check-circle text-indigo-600 mr-2"></i> Bespoke Analytics</li>
          <li className="flex items-center"><i className="fas fa-check-circle text-indigo-600 mr-2"></i> Advanced Invoice Management</li>
          <li className="flex items-center"><i className="fas fa-check-circle text-indigo-600 mr-2"></i> Dedicated Support</li>
          <li className="flex items-center"><i className="fas fa-check-circle text-indigo-600 mr-2"></i> Charge back support</li>
        </ul>
        <div className="mt-6 text-center">
          <a href="/contact" className="px-6 py-3 bg-indigo-600 text-white font-medium rounded-md hover:bg-indigo-700">
            Contact Us
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

{/* Call to Action Section */}
<div className="bg-gradient-to-r from-purple-500 to-indigo-600 py-12">
  <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 lg:flex lg:items-center lg:justify-between">
    <h2 className="text-4xl font-extrabold tracking-tight text-white sm:text-5xl">
      <span className="block">Simplify Your Billing Process</span>
      <span className="block text-yellow-300">Manage Customers, Subscriptions, and Invoices with Ease</span>
    </h2>
    <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
      <div className="inline-flex rounded-md shadow">
        <a href="/signup" className="inline-flex items-center justify-center px-8 py-4 border border-transparent text-lg font-medium rounded-md text-indigo-600 bg-white hover:bg-gray-200">
          Get Started Today
        </a>
      </div>
    </div>
  </div>
</div>

        <footer className="bg-gray-900 text-gray-300 py-12">
  <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
    <div className="grid grid-cols-2 md:grid-cols-5 gap-8">
      
      {/* Column 1: Company Info */}
      <div className="space-y-4">
        <h3 className="text-lg font-bold text-white">Transacly.co</h3>
        <p className="text-gray-400">
          Simplifying Stripe Invoicing & Product Management for your business. Manage, track, and get paid faster with Transacly.
        </p>
        <div className="flex items-center space-x-4">
          {/* X Icon */}
          <a href="https://x.com/Transacly" target="_blank" rel="noopener noreferrer" className="hover:text-gray-100">
            <i className="fab fa-x-twitter text-2xl"></i>
          </a>
          {/* Product Hunt Badge */}
          <a href="https://www.producthunt.com/posts/transacly-co?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-transacly&#0045;co" target="_blank" rel="noopener noreferrer">
            <img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=480454&theme=neutral" alt="Transacly.co - Stripe Invoicing & Product Management Platform | Product Hunt" className="h-12 w-auto" />
          </a>
        </div>
        <p className="text-gray-500 text-sm">&copy; 2024 Transacly - A proxitech company. All rights reserved.</p>
      </div>

      {/* Column 2: Product */}
      <div className="space-y-4">
        <h3 className="text-lg font-bold text-white">Product</h3>
        <ul className="space-y-2">
          <li><a href="#features" className="hover:text-gray-100">Payment Methods</a></li>
          <li><a href="#invoicess" className="hover:text-gray-100">Invoices</a></li>
          <li><a href="#features" className="hover:text-gray-100">Recurring Billing</a></li>
          <li><a href="#features" className="hover:text-gray-100">Product Management</a></li>
          <li><a href="#features" className="hover:text-gray-100">Tax Compliance</a></li>
        </ul>
      </div>

      {/* Column 3: Use Cases */}
      <div className="space-y-4">
        <h3 className="text-lg font-bold text-white">Use Cases</h3>
        <ul className="space-y-2">
          <li><a href="#" className="hover:text-gray-100">Freelancers</a></li>
          <li><a href="#" className="hover:text-gray-100">Small Businesses</a></li>
          <li><a href="#" className="hover:text-gray-100">Subscription Services</a></li>
          <li><a href="#" className="hover:text-gray-100">Digital Products</a></li>
        </ul>
      </div>

      {/* Column 4: Resources */}
      <div className="space-y-4">
        <h3 className="text-lg font-bold text-white">Resources</h3>
        <ul className="space-y-2">
          <li><a href="https://transacly.statuspage.io/" className="hover:text-gray-100">Help Center</a></li>
          <li><a href="https://transacly.statuspage.io/" className="hover:text-gray-100">System Status</a></li>
        </ul>
      </div>

      {/* Column 5: Company */}
      <div className="space-y-4">
        <h3 className="text-lg font-bold text-white">Company</h3>
        <ul className="space-y-2">
          <li><a href="#faq" className="hover:text-gray-100">About</a></li>
          <li><a href="#pricing" className="hover:text-gray-100">Pricing</a></li>
          <li><a href="https://transacly.co/policies" className="hover:text-gray-100">Privacy Policy</a></li>
          <li><a href="https://transacly.co/policies" className="hover:text-gray-100">Terms of Service</a></li>
        </ul>
      </div>
    </div>

    {/* Footer Bottom Section */}
    <div className="mt-12 flex justify-between border-t border-gray-700 pt-8 text-sm text-gray-400">
      <p className="text-sm">&copy; 2024 Transacly - A proxitech company. All rights reserved.</p>
      <div className="flex space-x-4">
        <a href="https://www.producthunt.com" target="_blank" rel="noopener noreferrer" className="hover:text-gray-100">Product Hunt</a>
        <a href="https://nextjs.org" target="_blank" rel="noopener noreferrer" className="hover:text-gray-100">Next.js</a>
        <a href="https://stripe.com" target="_blank" rel="noopener noreferrer" className="hover:text-gray-100">Stripe</a>
        <a href="https://vercel.com" target="_blank" rel="noopener noreferrer" className="hover:text-gray-100">Vercel</a>
        <a href="https://reactjs.org" target="_blank" rel="noopener noreferrer" className="hover:text-gray-100">React</a>
      </div>
    </div>
  </div>
</footer>
      </main>
    </div>
  );
};

export default HomePage;