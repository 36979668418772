import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import NavigationBar from './NavigationBar';

const Security = () => {
  const [formData, setFormData] = useState({
    password: '',
    newPassword: '',
  });
  const [status, setStatus] = useState('');
  const [subscription, setSubscription] = useState(null);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();


  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(
        'https://api.transacly.co/api/change-password',
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setStatus('Password changed successfully');
    } catch (err) {
      setStatus('Error changing password');
    }
    setLoading(false);
  };

  const handleCancelSubscription = async () => {
    if (subscription && window.confirm('Are you sure you want to cancel your subscription?')) {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.post(
          'https://api.transacly.co/api/user-cancel-subscription',
          { subscriptionId: subscription.id },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data.success) {
          setSubscription(null); // Reset the subscription state
          alert('Subscription cancelled successfully.');
        } else {
          setStatus('Error cancelling subscription.');
        }
      } catch (err) {
        setStatus('Error cancelling subscription.');
      }
    }
  };

  return (
    <div className="min-h-screen">
      <NavigationBar handleLogout={handleLogout} />
      <div className="pt-20 p-6 md:ml-64">
        <h2 className="text-3xl font-bold mb-6 text-center text-blue-600">Security Settings</h2>

        {/* Password Change */}
        <section className="mb-6 bg-white p-6 rounded shadow-lg">
          <h3 className="text-2xl font-bold text-gray-800">Change Password</h3>
          <form onSubmit={handlePasswordChange}>
            <div className="mb-4">
              <label className="block text-gray-700" htmlFor="password">Current Password</label>
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                className="w-full border rounded-lg py-2 px-4"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700" htmlFor="newPassword">New Password</label>
              <input
                type="password"
                name="newPassword"
                value={formData.newPassword}
                onChange={handleChange}
                className="w-full border rounded-lg py-2 px-4"
                required
              />
            </div>
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
              disabled={loading}
            >
              {loading ? 'Updating...' : 'Change Password'}
            </button>
          </form>
          {status && <p className="mt-4 text-center text-red-500">{status}</p>}
        </section>


        {/* Delete Account Section */}
        <section className="bg-white p-6 rounded shadow-lg">
          <h3 className="text-2xl font-bold text-gray-800">Request Account Deletion</h3>
          <p className="text-gray-600 mt-4">
            Deleting your account is a permanent action. To ensure this is really what you want, we require you to
            submit a request for account deletion through our support form. Once your request is received, we will
            begin processing your request.
          </p>
          <p className="text-gray-600 mt-4">
            After submitting your request, there will be a 30-day waiting period during which your account will be
            deactivated but not yet deleted. This gives you the opportunity to change your mind. If you wish to reactivate
            your account during this time, you can contact our support team.
          </p>
          <p className="text-gray-600 mt-4">
            After 30 days, if no reactivation is requested, all your personal data will be permanently removed from our
            systems. If you wish to proceed, please click the link below to request account deletion.
          </p>
          <Link
            to="/support"
            className="mt-6 inline-block bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
          >
            Request Account Deletion
          </Link>
        </section>
      </div>
    </div>
  );
};

export default Security;