// PM-Grid.js
import React from 'react';

const PMGrid = ({
  currentProducts = [],  // Default empty array if not passed
  toggleDropdown,
  dropdowns = {},  // Default empty object if not passed
  handleEditClick,
  handleShowDeleteModal,
  openInvoiceModal,
  handleGenerateLink,
  isDarkMode,
}) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {currentProducts.map((product) => (
        <div key={product.product_id} className={`p-4 rounded shadow-md relative ${isDarkMode ? 'bg-gray-800' : 'bg-white'}`}>
          <div className="flex justify-between items-center">
            <h4 className="text-lg font-semibold">{product.name}</h4>
            <div className="relative">
              <button onClick={() => toggleDropdown(product.product_id)} className="text-gray-500 hover:text-gray-700 focus:outline-none">
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
                </svg>
              </button>
              {dropdowns[product.product_id] && (
                <div className="absolute right-0 mt-2 w-32 bg-white rounded-lg shadow-lg">
                  <button
                    onClick={() => handleEditClick(product)}
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-200"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => handleShowDeleteModal(product)}
                    className="block px-4 py-2 text-sm text-red-600 hover:bg-gray-200"
                  >
                    Delete
                  </button>
                  <button
                    onClick={() => openInvoiceModal(product)}
                    className="block px-4 py-2 text-sm text-blue-600 hover:bg-gray-200"
                  >
                    Invoice
                  </button>
                  <button
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    onClick={() => handleGenerateLink(product)}
                  >
                    Link
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="my-4">
            {/* Placeholder for product image */}
            <div className="w-full h-32 bg-gray-200 rounded mb-2">
            <img
              src={product.image_url || '/placeholder.png'}
              alt={product.name || 'Placeholder'}
              className="w-full h-32 object-cover rounded mb-2"
            />
            </div>
            <p className="text-gray-600 truncate">{product.description || 'No description available'}</p>
            <p className="text-gray-600 mt-2">
              Price: {product.price} {product.currency ? product.currency.toUpperCase() : 'N/A'}
            </p>
            <p className="text-gray-600">
              Interval: {product.interval ? product.interval.charAt(0).toUpperCase() + product.interval.slice(1) : 'N/A'}
            </p>
            <p className="text-gray-600">Tax Code: {product.tax_code}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default PMGrid;